@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .recent_fact_check_title_text_ta {
    font-size: 21px !important;
    line-height: 32px !important;
  }
  .home_user_contribution_two_profile {
    font-size: 20px !important;
  }
  .related_fact_check_title_container > h4 {
    font-size: 40px;
  }
  .recent_fact_check_title_container > h4 {
    font-size: 40px;
  }
  .comment_details_user_name {
    font-size: 23px !important;
  }
  .comment_details_time {
    font-size: 20px !important;
  }
  .comments_parent_comment_reply_button {
    font-size: 30px !important;
  }
.comments_parent_comment_text{
  font-size: 25px;
}
.see_more_text{
  font-size: 27px;
}


.show_all_button_container {
  height: 80px !important;
}

.comments_input_button_group {
height: 70px;
}
.comments_primary_input{
  height: 100%;
  font-size: 25px;
}
.comments_send_button{
  height: 90%;
font-size: 25px;
}
.recent_fact_check_title_text {
  font-size: 20px !important;
  line-height: 38px !important;
}
  .featured_story_fact_check_title {
    font-size: 26px;
  }
  .featured_story_fact_check_sub_title {
    font-size: 23px;
  }

  .single_fact_check_claim_container {
    top: 400px;
    bottom: 200px !important;
    position:sticky;
}

.single_article_report_button {
  height: 50px;
  font-size: 25px;
}

.whatsapp_channel_text {
  font-size: 21px;
  line-height: 35px;
}
.reply_comment_name_text {
  font-size: 18px !important;
}

.reply_comment_date_text {
  font-size: 15px !important;
}
.reply_comment_text{
  font-size: 18px;
}
}
