/* Mobile styles (up to 767px) */
:root {
  --youturn-gradient: linear-gradient(
      104.29deg,
      #140f2d 41.31%,
      #f49d36 218.62%
    ),
    linear-gradient(111.39deg, #140f2d 20.79%, #f49d36 206.26%);
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .donation-card-div {
    display: none !important;
  }

  .fact_check_party_wise_selector {
    background-color: yellow !important;
    /* margin: 10% !important; */
    margin-top: 7% !important;
    width: 85vw !important;
  }

  .party-wise-update-title {
    width: 100% !important;
    font-size: 2vh !important;
  }
  .party-wise-update-fist-row {
    height: auto;

    flex-direction: column !important;
    margin-top: 30px;
    align-items: center;
  }
  .party-wise-update-fist-div {
    /* margin-left: 35px;
    margin-left: 65px; */
    /* display: flex !important; */
    flex-direction: column !important;
    width: 90%;
  }
  .trending_stories_main_card {
    border-radius: 12px !important;
    height: 25vh !important;
    min-width: 38vh !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-size: cover;
  }
  .social_media_logo {
    width: 80% !important;
    height: 42vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 6%;
  }

  .six-categories-card-first-div {
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
  }
  .six-categories-card-second-div {
    width: 90%;
    /* margin-left: 10%; */
    display: flex;
    flex-wrap: wrap;
    gap: 2.5vh;
    height: 20%;
  }

  .see_all_article_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .related_fact_check_card_wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  .single_article_recommended_stories {
    width: 100%;
    /* height: 55vh; */
  }

  .single_article_related_fact_check {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    padding: 0 5% 0 5%;
  }
  /* .fact_check_category_card_text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 3%;
  } */

  .footer-first-col {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    margin-top: 25px; */
    display: none;
  }
  .footer {
    width: 100%;
    margin: auto;
    justify-content: space-evenly;
    background-color: #fafcff;
    padding: 20px;
  }
  .contact-text {
    padding: 5px;
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .subscribe-youtube-videos {
    background-color: #0065cc;
    color: #ffffff;
    border-radius: 5px;
    width: 80vw;
    border: none;
  }
  .image_small_card_image {
    height: 100%;
    width: 100%;
  }

  .image_small_card {
    width: 40%;
    height: 100%;
    background-color: #0065cd;
  }

  .browse_category_mobile_view {
    margin-top: 1.9vh !important;
  }

  .social_media_list {
    /* background-color: yellow !important; */
    display: none !important;
  }

  .divider_see_all_button {
    display: block;
    width: 100%;
    /* background-color: #05ae0e; */
    justify-content: center;
  }
  .see_all_button {
    width: 100%;
    /* margin-top: 2vh; */
  }

  .fact_check_category_card {
    width: 100% !important;
    position: relative;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
    cursor: pointer;
    border-radius: 10px !important;
  }

  .fact_check_categories_list {
    display: none;
  }
  .browse_categories_text {
    width: 100% !important;
    margin-left: 6%;
    line-height: 4vh !important;
  }

  .fact_check_donation_card {
    background-color: #140f2d;
    width: 100% !important;
    border-radius: 0 !important;
    border-color: #140f2d;
  }

  .fact_check_mobile_row {
    background-color: "#05ae0e";
  }

  .fact_check_card_mobile_view {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    align-items: center;
    /* height: 31vh !important; */
  }

  .news_article_card_content {
    display: none !important;
  }
  .user_contribution_text_and_image {
    display: flex;
    flex-direction: row !important;
    background-color: #05ae0e;
  }
  .fetch_categories_mobile_view {
    display: none !important;
  }
  .fetch_media_share_mobile_view {
    display: none !important;
  }

  .news_article_card_content {
    display: none !important;
  }
  .news_article_card_content-mobile {
    display: block !important;
  }
  .Stories {
    margin-top: -40px !important;
  }

  .carousel-desktop {
    display: none !important;
  }

  .exposing-fake-news-image {
    display: block;
    height: 100vh !important;
    width: 90vw;
    display: flex;
    margin: auto;
    margin-left: 52px !important;
  }
  .custom-news-story-card {
    min-width: 90%;
    max-width: 95%;
  }
  .feedback-form {
    width: 100%;
    /* margin-top: 20px;
    width: 100% !important;
    margin-top: -37px; */
  }
  .contact-text {
    width: 100% !important;
  }
  .hide_content {
    display: none !important;
  }
  .you-turn-logo-text {
    font-size: 25px !important;
    margin-top: 20px;
    margin-left: 4px;
    font-weight: bold;
  }

  .mobile_drawer_moon {
    /* height: 24px !important; */
    width: 24px !important;
    margin-left: 5px !important;
    /* margin-top: -20px !important; */
  }

  /* .mobile_drawer_title {
    font-size: 18px !important;
    margin-left: 10px !important;
    margin-top: -9px !important;
    display: flex;
    width: 26vw;
  } */
  /* 
  .mobile_drawer_logout_icon {
    margin-left: -15px;
  } */

  .mobile_drawer_line {
    background-color: white;
    width: 90% !important;
    /* margin-top: 70px !important; */
    margin-left: 20px !important;
    border-bottom: 2px solid white;
  }
  .mobile_drawer_social_media_icon {
    margin-left: 10px !important;
  }
  .drawer_fb_icon {
    margin-left: -33px;
  }
  .social_media_image {
    margin-left: 25px;
  }
  .social_media_img {
    margin-left: 25px;
  }

  .single_article_small_fact_check_card {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /* height: 89%; */
    gap: 30px;
    /* padding: 0 0 5% 0 !important; */
  }
  .single_article_related_fact_check_card {
    height: auto !important;
    margin-top: 70px;
  }

  .single_article_fact_check_categories {
    width: 100%;
    height: 100%;
  }

  .contact-text {
    padding: 5px;
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .image_small_card_image {
    height: 100%;
    width: 100%;
  }

  .image_small_card {
    width: 40%;
    height: 100%;
    background-color: #0065cd;
  }

  .social_media_list_title {
    font-weight: bold;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 25px !important;
  }

  .fact_check_category_card_image {
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .fact_check_category_card_image_div {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .related_fact_check_card_last_text {
    font-size: 1.7vh !important;
    line-height: 2.5vh !important;
  }

  .fact_check_mobile_row {
    background-color: "#05ae0e";
  }

  .news_article_card_content {
    display: none !important;
  }
  .user_contribution_text_and_image {
    display: flex;
    flex-direction: row !important;
    background-color: #05ae0e;
  }
  .fetch_categories_mobile_view {
    display: none !important;
  }
  .fetch_media_share_mobile_view {
    display: none !important;
  }

  .news_article_card_content {
    display: none !important;
  }
  .news_article_card_content-mobile {
    display: block !important;
  }
  .Stories {
    margin-top: -40px !important;
  }

  .custom-news-story-card {
    min-width: 90%;
    max-width: 95%;
  }

  .contact-text {
    width: 100% !important;
  }

  .mobile_drawer_social_media_icon {
    margin-left: 10px !important;
  }
  .drawer_fb_icon {
    margin-left: -33px;
  }
  .social_media_image {
    margin-left: 25px;
  }
  .social_media_img {
    margin-left: 25px;
  }
  /* .mobile_drawer_content_list {
@media only screen and (min-width: 320px) and (max-width: 767px) {
.fact_check_category_card {
  /* background-color: red; */
  /* margin-right: 10vh; */

  .fact_check_categories_sub_title {
    padding: 3%;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 60px;
    font-size: 1.5vh;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.13);
  }

  .fact_check_category_card_text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    /* height: 35%; */
    background-color: rgba(20, 15, 45, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 3%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .contact-text {
    padding: 5px;
    color: white;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .contact-page {
    /* background-color: #140f2d; */
    flex-direction: column;
  }

  .image_small_card_image {
    height: 100%;
    width: 100%;
  }
  .fact_check_rectangle_content {
    height: 9.3vh !important;
    width: 53vw;
  }
  .rectangle_small_card_image {
    /* width: 141px !important;
    height: 13vh !important; */
    object-fit: cover;
    width: 37vw !important;
  }
  .image_small_card {
    width: 40%;
    height: 100%;
    background-color: #0065cd;
  }
  .landing_page_news_articles {
    display: none !important;
  }

  .social_media_list_card {
    width: 100% !important;
    padding: 5px !important;
    padding-bottom: 12% !important;

    background: linear-gradient(
      166.4deg,
      #140f2d 9.74%,
      #f49d36 281.54%
    ) !important;
    height: 64vh !important;
    /* height: 400px !important; */

    border: transparent !important;
    /* box-shadow: 0 0 10px rgba(0, 0, 255, 0.3) !important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .fact_check_categories_list_selector {
    display: flex;
    justify-content: center;
  }

  .donate_button_width {
    width: 85vw !important;
  }
  .donation_button_note {
    width: 85vw !important;
    margin-top: 2px;
    /* text-align: justify !important; */
    /* background-color: pink; */
  }

  .fact_check_donation_small_card_mobile_view {
    width: 100% !important;
    margin-top: -30vh !important;
    /* margin-top: 28vh !important; */
  }

  .icon_card .icon_card_first_col {
    /* order: 1 !important; */
  }

  .icon_card_image {
    width: 60px !important;
  }

  .icon_card .icon_card_second_col {
    order: 2 !important;
  }
  .single_article_view_icon {
    display: none !important;
  }

  .single_article_title_row {
    height: 19vh;
    width: 100%;
    align-items: center;
  }
  .single_article_donation_and_social_media {
    display: none !important;
  }

  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }
  .article_page_content_col {
    width: 90% !important;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .article_page_content_image {
    width: 310px !important;
    margin-top: 25px;
  }
  .stay_connected_card {
    display: none !important;
  }
  .antibiotic_content_row {
    width: 100% !important;
  }
  .antibiotic_content_col_first {
    width: 310px !important;
  }

  .continue_reading_button {
    margin-top: 10px;
  }
  /* .save_icon {
    display: none !important;
  } */

  .report_icon {
    display: none !important;
  }

  .report_share_icon {
    gap: 5px;
    align-items: center;
  }

  .view_report_share {
    margin-top: 30px;
  }
  .single-fact-check-view_report_share {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 13vh;
  }

  .donation-card-container {
    position: sticky !important;
    top: 0;
    z-index: 2;
    width: 27%;
  }

  .fact_check_rectangle_name {
    font-size: 12px !important;
  }

  .fact_check_category {
    width: 380px !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .landing_page_news_story_card_vertical {
    display: none !important;
  }
  .landing_page {
    /* background-color: #140f2d;
    z-index: 2;
    position: relative;
    padding: 20px;

    height: 155px !important;
    width: 100%;
    box-shadow: 0 20px 50px 30px #140f2d; */
    display: none;
  }
  .signatory_image {
    width: 100px !important;
    margin-left: 260px;
    margin-top: -250px !important;
    cursor: pointer;
  }
  .landing_page_titles {
    height: 220px !important;
    width: 300px;
    align-items: center !important;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
  }

  .landing_page_news_article {
    display: none !important;
  }

  .fact_check_card_mobile {
    width: 315px !important;
    height: 200px !important;
    border-radius: 20px;
  }
  .custom-fact-check-card-first-half {
    width: calc(165vw / 2) !important;
    height: 14.5vh;
    margin-top: 14.9vh !important;
    background-color: rgba(20, 15, 45, 0.9);
    /* margin-left: -20px; */
    margin-right: 4vh;
    position: absolute;
    z-index: 2;
  }
  .fact_check_inside_card_mobile_view {
    width: calc(159vw / 2) !important;
    height: calc(35vh / 2);
    margin-top: 20vh !important;
    background-color: rgba(20, 15, 45, 0.9);
  }

  .article_page_button {
    display: none !important;
  }

  .fact_check_inside_card_width {
    height: 40px;
    margin: 12% 0 0 4%;
    gap: 1vh;
  }

  .fact_check_inside_card {
    width: 226px;
    display: flex;
    justify-content: space-between;
    margin: 4% 0 2% 4%;
  }

  /* 
=======
.fact_check_mobile_row {
  background-color: "#05ae0e";
}

 
 

.news_article_card_content {
  display: none !important;
}
.user_contribution_text_and_image {
  display: flex;
  flex-direction: row !important;
  background-color: #05ae0e;
}
.fetch_categories_mobile_view {
  display: none !important;
}
.fetch_media_share_mobile_view {
  display: none !important;
}

.news_article_card_content {
  display: none !important;
}
.news_article_card_content-mobile {
  display: block !important;
}
.Stories {
  margin-top: -40px !important;
}

  .carousel-desktop {
    display: none !important;
  }

 

  .custom-news-story-card {
    min-width: 90%;
    max-width: 95%;
  }

  .contact-text {
    width: 100% !important;
  }
  

 

  
  

  .mobile_drawer_social_media_icon {
    margin-left: 10px !important;
  }
  .drawer_fb_icon {
    margin-left: -33px;
  }
  .social_media_image {
    margin-left: 25px;
  }
  .social_media_img {
    margin-left: 25px;
  }
  /* .mobile_drawer_content_list {
    font-size: 16px !important;
    margin-top: 30px !important;
  } */
  .you-turn-text-content {
    margin-top: 5px;
    width: 95%;
    text-align: justify;
  }
  /* .bell-icon-mobile {
    display: none;
  } */

  .single_article_title {
    color: #000000 !important;
    line-height: 3vh !important;
    /* height: 15vh; */
    font-size: 2vh;
    font-weight: bold !important;
    overflow: hidden;
    padding-bottom: 3%;
  }
  .about-text {
    display: none;
  }
  .mission-text-container {
    height: auto !important;
    width: 100%;
  }
  .you-turn-logo {
    margin-top: 1px !important;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .you-turn-image {
    width: 300px !important;
  }
  .you-turn-content {
    font-size: 12px !important;
  }
  .you-turn-text {
    width: 100% !important;
    padding: 7px;
  }
  .you-turn-logo-container {
    width: 100% !important;
  }
  .our_mission_container {
    width: 100% !important;
    padding: 2px;
  }
  .mission-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .our-mission-text {
    width: 300px !important;
    justify-content: center;
  }

  .desktop_header_search {
    display: none !important;
  }
  .desktop_header_menu {
    display: none !important;
  }
  .donation-card {
    width: 100%;
    border-radius: 0% !important;
    background: var(--youturn-gradient);
    padding: 2% 5% 2% 5% !important;
    /* height: 79vh !important; */
  }
  .mobile_menu_logo {
    justify-content: flex-start !important;
  }
  .menu_logo {
    height: auto;
  }
  .mobile_menu_logo {
    flex-direction: row-reverse !important;
    width: 0% !important;
    margin-left: 40% !important;
    gap: 10px;
    justify-content: right !important;
  }
  .mobile_header_image {
    margin-left: 200px !important;
  }

  /* .your-row-class {
    width: 100%;
    height: 37vh;
    background-color: #140f2d;
  } */

  .your-row-class {
    display: none !important;
  }

  .your-row-class .ant-col:first-child {
    order: 2;
  }

  .your-row-class .ant-col:last-child {
    order: 1;
  }
  .single_article_page_second_col_antibiotic {
    width: 100% !important;
    height: 30vh;
    display: flex;
    justify-content: center;
    /* margin-left: 25px; */
  }
  .single_article_image {
    width: 100% !important;
  }
  .single_article_page_first_col_antibiotic {
    width: 100% !important;
    background-color: pink;
    /* height: 50vh; */
  }
  /* .single_article_page_top_content {
    height: 620px;
  } */

  .single_fact_icon_card_image {
    width: 10vh !important;
    margin-top: -18vh !important;
    margin-left: -22vh !important;
  }
  .mobile-views-year-div {
    margin-left: 170px;
    width: 100%;
    margin-top: -98px;
    height: auto !important;
    background-color: #0065cd;
  }
  .single_article_report_share_icons {
    display: none;
  }

  .icon_card {
    padding: 0 0 0 2%;
    align-items: center;
  }

  .icon_card .icon_card_second_col {
    /* order: 2 !important; */
  }

  .icon_card_text_bold {
    height: 3vh;
    font-size: 2.1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .icon_card_text {
    height: 3vh;
    font-size: 2.1vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .article_page_content_row {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .single_article_continue_reading {
    background-color: #0065cc;
    border: transparent;
    color: white;
    width: 310px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }

  .article_page_content_image {
    width: 310px !important;
    margin-top: 25px;
  }
  .stay_connected_card {
    display: none !important;
  }
  .antibiotic_content_row {
    width: 100% !important;
  }
  .antibiotic_content_col_first {
    width: 310px !important;
  }
  .report_card {
    display: none !important;
  }
  .continue_reading_button {
    margin-top: 10px;
  }

  .donation-card-container {
    position: sticky !important;
    top: 0;
    z-index: 2;
    width: 27%;
    background-color: red;
  }
  .fact_check_rectangle_card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    border-radius: 8px;
    height: 11.3vh !important;
    overflow: hidden;
    user-select: none;
    width: 100%;
  }

  .fact_check_category {
    width: 380px !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .you_turn_presents {
    margin-top: -165px;
    margin-left: -144px;
    position: absolute;
    font-size: 2vh !important;
  }

  .landing_page_mobile_view_title {
    width: 210px;
    height: 90px;
    line-height: 2.5;
    margin-left: -77px;
    position: relative;
  }

  .exposing_fake_news_card {
    width: 100%;
    /* height: 360px; */
  }
  .exposing_fake_news {
    height: 300px !important;
    gap: 10px !important;
    width: 100% !important;
    /* display: flex;
    flex-direction: column; */
  }
  .exposing_fake_news_text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px !important;
    margin-top: 20px;
  }
  .fact_check_card_mobile {
    width: 70vh !important;
    height: 45vh !important;
    /* width: 100% !important; */
    /* height: 200px !important; */
    border-radius: 10px;
    z-index: 1;
  }
  .article_top_image {
    width: 100% !important;
    border-radius: 10px;
    z-index: 1;
    cursor: pointer;
  }

  .categories_selector {
    width: 90%;
  }

  .fact_check_inside_card_title {
    font-size: 1.5vh !important;
    width: 75vw;
    /* height: 9vh !important; */
    margin: auto;
  }

  .new_you_turn_article_title {
    display: flex;
    flex-direction: column;
    width: "52%";
    justify-content: space-between;
    width: 100% !important;
    margin-left: 42px !important;
  }

  .fact_check_rectangle_news {
    /* font-size: 1.2vh !important; */
    font-weight: bold;
    line-height: 1.9vh !important;
  }
  .fact_check_category {
    width: 380px !important;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .landing_page_title {
    display: none;
  }

  .fact_check_card_mobile {
    width: 70vh !important;
    height: 45vh !important;
    /* width: 100% !important; */
    /* height: 200px !important; */
    border-radius: 10px;
    z-index: 1;
  }

  .view_icon {
    color: #e84756;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
  }

  .view_count_and_icon {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }

  .new_you_turn_article {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
  .news_article_card {
    width: 100% !important;
    height: auto !important;
  }

  .news_article_card_content {
    display: block !important;
    width: 100% !important;
    height: auto !important;
    flex-direction: column;
    margin-left: 5% !;
    padding: 20% 0 0 0;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .see_all_story_button {
    margin-top: 30px !important;
  }
  .you_turn_video_card_mobile_screen {
    width: 100% !important;
    height: auto;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    margin-top: 42px;
  }

  .you_turn_video_card {
    margin-top: 130px;
  }
  .video_content {
    width: 90% !important;
  }
  .custom_small_video_card {
    width: 60vw !important;
    flex-direction: column;
  }
  .video_card_image {
    width: 80vw !important;
    height: 21vh !important;
    object-fit: cover;
  }
  .video_card_content {
    width: 40% !important;
    gap: 6px !important;
    align-items: center;
    justify-content: center;
  }
  .video_and_content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    height: 85vh;
  }
  .submit_your_claim {
    /* width: 100% !important;
    height: 65vh !important;
    display: flex;
    flex-direction: column; */
    display: none !important;
  }
  .submit_your_claim_form {
    width: 88vw !important;
    /* height: 35vh; */

    justify-content: center;
    align-items: center !important;
    /* margin-top: -30px !important; */
  }
  .submit_claim {
    width: 100px !important;
  }
  /* .submit_your_claim_form {
    display: flex;
    justify-content: center;
  } */
  .topic_input {
    width: 40px;
  }
  .award_card {
    width: 100% !important;
    height: auto;
    display: flex;
    gap: "20px";
  }
  .award_and_recognition {
    width: 100% !important;
    height: 48vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* background-color: #05ae0e; */
  }
  .award-recognition-mobile {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .award_image {
    width: 85% !important;
    /* width: 320px !important; */
    height: 240px;
    z-index: 1 !important;
    opacity: 0.5;
    border-radius: 8px;
    border: 2px solid white !important;
  }
  .award_card_content {
    margin-left: 100px !important;
    position: absolute;
    height: 60px;
    width: 240px;
    background-color: var(--award-content);
    margin-left: 158px;
    margin-top: 165px;
    z-index: 2;
    display: flex;
  }
  .award_recognition_card {
    /* height: 670px; */
    /* height: 80vh !important; */
    width: 100%;
    margin-top: 50px;
    box-shadow: inset -80px 0 70px 0 #4d332f !important;
    background: linear-gradient(
      147.32deg,
      #140f2d 40.85%,
      #f49d36 169.52%
    ) !important;
    height: auto !important;
  }
  .award_and_recognition_title {
    width: 85%;
    margin: 20px 0 20px 0;
    height: 9vh !important;
  }
  .awards-div {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .become_member {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .user_contributed_stories_mobile_view {
    width: 100% !important;
    display: flex;
    justify-content: center;
    /* margin-left: 30px;
    margin-top: 30px; */
  }

  .slogan {
    font-size: 17px !important;
  }
  .you_turn_present_text {
    margin-top: 30px;
  }

  .social-media-list {
    width: 100% !important;
  }
  .report-card {
    width: 700px !important;
    height: 355px !important;
  }
  .text-size {
    font-size: 35px !important;
  }

  .big-screen {
    display: none !important;
  }

  .social-media {
    /* margin-top: 20px;
    display: flex;
    width: 100% !important; */
    display: none;
  }
  .subscribe-box {
    width: 100% !important;
  }
  .quickaccess {
    margin-top: 7vh !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .followus-card {
    width: 10vw;
  }
  .media-logo {
    width: 8vw !important;
  }

  .subscribe-checkbox {
    display: flex;
    width: 100% !important;
    text-align: center;
  }
  .big-screen {
    display: none !important;
  }
  .quick-access {
    width: 100% !important;
    /* height: 30vh; */
    display: flex;
    align-items: center;
    margin: auto;
    padding: 3% 0 0 0;
  }

  .subscription-container {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
  }

  .social-media-container-big {
    width: 100% !important;
  }
  .submit-claim-widget {
    width: 310px !important;
    margin-left: 13px;
  }
  .detail-accordion {
    width: 290px;
    padding: 10px;
    /* font-family: "Lato"; */
    word-spacing: normal;
  }
  .side-menu-overlay {
    padding: "10px";
    color: white;
    width: 100%;
    height: 110dvh;
    background: linear-gradient(179.57deg, #140f2d -12.19%, #f49d36 405.97%);
  }
  .side-menu-overlay-header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .hello-guest {
    padding: 15px;
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .account-profile {
    width: 34px;
    background-color: white;
    height: 34px;
    border-radius: 50%;
    padding: 1px;
    margin: 10px;
  }
  .hello-guest__profile {
    display: flex;
    width: 200px;
    align-items: center;
    color: white;
  }
  .signin-box {
    border: 1px solid white;
    border-radius: 5px;
    padding: 3px 14px 3px 14px;
  }
  .join_us {
    width: 100% !important;
    height: 230px !important;
    display: flex;
    flex-direction: row;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
  }
  .join_us_card_image {
    display: none !important;
  }
  .custom-input {
    width: 170px !important;
  }
  .footer_check_box_with_text {
    width: 100% !important;
    margin-top: 15px;
    margin-left: -20px;
    display: flex !important;
    flex-direction: row !important;
  }
  .privacy_policy {
    margin-top: -2px !important;
    margin-left: 2px !important;
  }
  .or_text {
    width: 20px !important;
    /* } */
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
  }

  .custom-input {
    width: 170px !important;
  }

  .privacy_policy {
    margin-top: -2px !important;
    margin-left: 2px !important;
  }
  .or_text {
    width: 20px !important;

    /* background-color: #05ae0e; */
  }
  /* .download_card {
    width: 90%;
    background-color: linear-gradient(
      147.32deg,
      #140f2d 40.85%,
      #f49d36 169.52%
    );
  } */

  /* .custom-button {
    width: 298px !important;
  } */

  .donation-custom-button {
    /* width: 4!important; */
  }

  .news_story_card_small {
    width: 700px !important;
  }

  .submit_claim_button:active {
    transform: scale(0.9);
    transition: transform 0.09s ease-in;
  }
  .submit_claim_button {
    width: 298px !important;
  }

  .rectangle_small_card_mobile_view {
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    margin-top: 69px !important;
  }
  .news_article_carousel_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: "5px";
    padding: 3%;
  }
  .left_arrow_out_lined {
    font-size: 25px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 30px;
  }
  .right_arrow_out_lined {
    font-size: 25px;
    color: #0065cc;
    cursor: pointer;
    margin-top: 30px;
  }
  .news_article_carousel {
    display: flex;
    justify-content: center;
    height: 7vh;
    overflow: hidden;
  }
  .news_article_carousel_text {
    width: 100%;
    justify-content: space-between;
  }
  .exposing_fake_news_title {
    font-size: 27px !important;
    width: 100% !important;
    margin-bottom: 14px;
    font-weight: 900 !important;
    margin-top: 9px !important;
  }
  .carousel_title {
    width: 70%;
  }
  .user_contribution {
    width: 100% !important;
    height: 260px !important;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .user_contribution_img_with_content {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
  }
  .user_contribution_top_image {
    width: 100% !important;
  }
  .user_contribution_text {
    width: 100% !important;
    /* padding: 7% 7% 7% 7%;
    height: 70vh; */
    margin-top: 2vh;
    /* margin-top: 7vh; */
  }
  .user_contribution_headline {
    margin-top: 10px !important;
  }
  .user_contribution_headline_text {
    color: #140f2d;
    font-size: 3vh;
    font-weight: bold;
    font-style: Lato;
  }

  .user_contribution_content {
    margin-top: 2vh;
    /* background-color: antiquewhite; */
    height: 20vh;
    overflow: hidden;
  }
  .fact-check-title-and-subtitle {
    /* padding: 7%; */
    /* background-color: red ; */
    width: 100%;
  }
  .user_contribution_recently_added_component {
    margin-top: 480px;
  }

  .recommended_stories_title {
    margin: 20px 0 0 20px;
  }

  .recommended_stories {
    width: 100% !important;
    height: auto !important;
    flex-direction: column;
    margin-top: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 40px;
  }
  .news_article_card_content-desktop {
    display: none !important;
  }
  .article_list {
    /* margin: 100px auto 10px 35px !important; */
    gap: 30px !important;
    /* padding-top: 5px; */
  }
  .article_single {
    gap: 20px !important;
  }
  .recommended_stories_text {
    /* display: flex; */
    /* justify-content: left !important; */
    height: 10vh;
    align-items: flex-start;
    text-align: left;
  }
  .recommended_stories_title_box {
    width: 100%;
  }
  .recommended_stories_mobile_text {
    font-size: 3.5vh !important;
    line-height: 30px !important;
    text-align: left;
  }
  .article_news_story_card_vertical {
    display: none;
  }
  .article_article_small_card {
    /* height: 80% !important;
    display: flex;
    flex-direction: column;
    gap: 4vh; */
    display: none;
  }
  .article_small_card {
    /* height: 80vh !important;
    display: flex;
    flex-direction: column;
    gap: 8vh; */
    display: none;
  }
  .article_donation_card {
    display: none;
  }

  .article_join_us_card {
    padding: 10% 0 0 0;
  }
  .article_recommended_stories_container {
    height: 90%;
    padding: 15% 5% 0 5%;
    justify-content: space-between;
  }
  .join_us_card {
    height: 230px !important;
    width: 100%;
  }
  .join_us_card_title_and_button {
    height: 230px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
    align-items: center;
  }
  .become_member_button {
    margin-top: 20px !important;
    /* width: 100%; */
  }
  .join_us_content {
    /* margin-top: 20px !important; */
    padding: 0 5% 0 5%;
    line-height: 4vh !important;
    text-align: center;
    font-size: 2vh !important;
  }
  .join_us_button {
    width: 100%;
    border-radius: 9px !important;
    border: transparent;
  }
  .article_page {
    width: 100%;
    height: 32vh !important;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }
  .inner_article {
    width: 100%;
    /* height: 70vh !important; */
    /* height: auto !important; */
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: yellow; */
  }
  .article_top_title {
    width: 100%;
    height: 13vh;
    padding: 4%;
    background-color: #140f2d;
  }
  .article {
    display: none;

    /* width: 90% !important;
    height: auto; */
    /* height: 150vh; */
    /* align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    background-color: pink; */
  }
  .article_featured_story {
    color: #f49d36;
    font-weight: bold;
    font-size: 3vh !important;
    height: 6vh;
  }
  .article_headline {
    margin-top: 10px !important;
    height: auto;
    cursor: pointer;
  }
  .article_headline_text {
    font-size: 2.2vh;
    font-weight: 700;
    font-family: Lato;
  }
  .article_content {
    padding: 5% 0 0 0;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    text-align: justify;
  }
  .article_continue_reading_div {
    /* height: 10vh; */
    padding: 4% 0 0 0;
  }
  .article_continue_reading {
    color: #0065cc;
    text-decoration: underline;
    font-size: 2.2vh;
    font-weight: bold;
    cursor: pointer;
  }
  .article_sub_title {
    font-size: 2vh;
  }
  .article_title {
    margin-top: 20px;
    font-size: 4vh !important;
    font-weight: 700 !important;
  }

  .media_share {
    display: none;
  }
  .category_wise_article {
    display: none;
  }

  .article_browse_categories_title_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carousel_card {
    width: 100%;
    background-color: pink;
    align-items: center;
  }
  .article_carousel {
    background-color: #1e1745;
    height: 300px;
    margin-top: 40px;
  }
  .top_six_article_card {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .aligncenter {
    width: 100%;
    object-fit: contain !important;
    height: auto !important;
  }
  .article_browse_categories {
    width: 100%;
    /* height: auto; */
    margin-top: 50px;
    /* gap: 30px; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .article_browse_categories_text {
    width: 100%;
    height: 10%;
    display: flex;
    padding: 0 0 0% 5%;
    margin-bottom: 20px;
  }

  .top_six_article {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 3vh;
    /* padding: 3% 0 0 0; */
    height: 26%;
  }
  .party-wise-browse-container {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: start !important;
    align-content: start !important;
  }
  .party-wise-browse-content {
    width: 100% !important;
  }
  .party-wise-browse-content-two {
    width: 100% !important;
    padding: 40px;
    margin-left: 40px;
  }
  .party-wise-content {
    margin-left: 20px !important;
  }
  .party-wise-article {
    width: 100%;
    margin-left: 0 !important;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    height: 26%;
    padding: 20px;
  }
  .article-subtitle-text {
    height: 110px !important;
  }
  .part-wise-stories-secondary-container {
    /* display: grid;
    grid-template-columns: 100%; */
    flex-direction: column !important;
  }
  .part-wise-stories-secondary-container {
    height: auto !important;
  }
  .list-article-image {
    width: 30vh;
  }
  .fact-check-small-card-container {
    width: 23rem !important;
    height: 4.5rem !important;
  }
  .fact-check-mini-content-container {
    padding: 8px !important;
  }

  .single-article-page-first_division {
    width: 100%;
    height: 600px;
    display: flex !important;
    flex-direction: column !important;
    padding: 7%;
  }
  .single_article_page_bread_crumbs {
    width: 100%;
    padding: 7% 0 6% 7%;
  }
  .single_article_page_article_date {
    margin-top: 5px;
  }
  .single_article_view_report_share {
    margin-top: 30px;
    width: 100%;
  }
  .single_article_join_us_card {
    width: 100% !important;
    padding-top: 15%;
  }
  .list_of_article_top_image {
    width: 100%;
    border-radius: 10px;
    z-index: 1;
    cursor: pointer;
  }
  .list_of_article_inside_card {
    /* width: 245px !important; */
    width: 74vw !important;
    margin-top: 10vh !important;
    background-color: rgba(20, 15, 45, 0.9);
    z-index: 2;
    position: absolute;
  }
  .list_of_article_inside_card_width {
    height: 40px;
    margin: 4% 0 0 4%;
  }
  .list_of_article_inside_card_title {
    font-size: 1vh !important;
  }
  .list_of_article_view_count_and_icon {
    width: 20vh;
    display: flex;
    justify-content: space-between;
    margin: 4% 0 2% 4%;
  }
  .list_of_article_view_count_and_icon_div {
    display: flex;
    flex-direction: row;
    gap: 3px;
  }
  .list_of_article_page_button {
    display: none !important;
  }
  .fact_check_top_image {
    /* width: 77% !important; */
    /* min-width: 92vw; */
    height: 29.3vh !important;
    width: 100% !important;
    border-radius: 10px;
    z-index: 1;
    /* margin-left: 9px; */
    margin-top: 21px;
    object-fit: cover;
  }
  .article_page_inside_card {
    width: 245px !important;
    margin-top: 25% !important;
    background-color: rgba(20, 15, 45, 0.9);
    z-index: 2;
    position: absolute;
  }
  .top_article_inside_card_width {
    height: 6vh;
    margin: 2% 0 0 2%;
  }

  .article_inside_card_title {
    font-size: 9px !important;
    line-height: 2vh !important;
  }
  .article_inside_card {
    width: 226px;
    display: flex;
    justify-content: space-between;
    margin: 4% 0 2% 4%;
  }
  .article_page_inside_card_title {
    font-size: 9px !important;
  }
  .article_recommended_stories {
    display: flex;
    flex-direction: column;
    gap: 60px;
    height: auto !important;
  }
  .fact-check-fist-half-row {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .exposing-fake-news {
    display: flex;
    align-items: center;
    /* margin-left: -18vw; */
  }
  .single-fact-check-full-page {
    overflow: hidden !important;
  }

  .fact-check-row-class {
    background-color: #140f2d;
    height: 28vh !important;
    height: auto;
    display: flex;
    justify-content: center;
  }

  .fact-check-bread-crumbs-div {
    /* height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh; */
    display: none !important;
  }
  .video-check-bread-crumbs-div {
    /* height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center; */
    display: none !important;
  }

  .single-fact-check-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 20vh; */
    overflow: hidden;
    margin-top: 2vh;
    height: auto;
  }

  .single-fact-check-top-image-col {
    margin-top: 10px;
  }

  .single-fact-check-claim-column {
    width: 100%;
  }
  .single-fact-check-content-div {
    display: flex;
    justify-content: center;
  }
  .single_fact_check_explanation {
    width: 91%;
  }
  .single-fact-check-content-title {
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .single-fact-check-related-fact-check-title {
    font-size: 2.1vh !important;
    line-height: 3vh !important;
  }
  .single-fact-check-content-title-div {
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .laptop-view-report-share {
    padding: 2.2vw;
    display: flex;
    justify-content: center;
  }
  .single-fact-check-comment-div {
    width: 100%;
  }

  .single-fact-check-comment-row {
    width: 100%;
  }
  .single-fact-check-comment-full-div {
    display: flex;
    justify-content: center;
  }
  .single-fact-check-fake-image-div {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
  }
  .single-fact-check-fake-image {
    width: 5vw;
  }
  .single-fact-check-claim-div {
    height: 8vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .laptop-report-donatio-row {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .single-fact-check-related-fact-check-col {
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .related-fact-check-title {
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .laptop-fact-check-categories {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3vh;
  }
  .single-fact-check-recently-added-col {
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fact-check-rectangle-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* height: 60vh; */
    gap: 3vh;
    /* background-color: pink; */
  }
  .related-fact-check-row {
    /* height: 177vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
  }
  .single-fact-check-related-fact-ful-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .single-fact-view-report-share {
    width: 99%;
  }
  .fact-check-second-div {
    position: relative;
    top: -18rem;
  }

  .party_tag_vertical_and_small_card_div {
    display: flex;
    flex-direction: column;

    width: 100% !important;
  }
  .landing_page_vertical_image {
    height: 20vh !important;
    width: 100vw !important;
    object-fit: contain;
    transform: "scale(1.10)";
  }
  .party-tag-news-card-vertical {
    display: none;
  }

  .party-wise-update-fact-check-small-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 2.5vh;

    margin-top: 5vh !important;
  }
  .fact-check-join-us-card {
    background-color: "red" !important;
    /* margin-top: 6vh !important; */
  }
  .divider_see_all_button_small_card {
    height: 13vh !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .fact-check-background-color-div {
    height: 50vh;
    background-color: #140f2d;
  }
  .fact_check_third_div {
    height: 100vh;
  }
  .fact-check-third-div {
    width: 100% !important;
  }

  .about-us-header {
    width: 100%;
    background-color: #140f2d;
    height: auto;
  }
  .tamil-about-description {
    color: white !important;
    font-size: 17px !important;
  }

  .about-us-container {
    width: 88%;
    margin-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .meet_the_team {
    background: linear-gradient(115deg, #111a42 10.75%, #f49d36 348.16%);
    width: 100% !important;
  }

  .meet_the_team_title {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 80% !important;
  }

  .meet_the_team_text {
    font-size: 4vh !important;
  }

  .meet_the_team_content {
    width: 93% !important;
  }
  .hide_medical_experts {
    display: none !important;
  }
  .team_members_profile {
    display: none !important;
  }
  .ifcn_container {
    display: flex;
    flex-direction: column;
  }
  .ifcn_text {
    display: none;
  }

  .ifcn_content {
    width: 85vw !important;
    height: auto !important;
    text-align: justify;
  }
  .mobile_ifcn_text {
    width: 100% !important;
    padding: 5%;
  }
  .ifcn_logo {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ifcn_donation {
    width: 80vw !important;
    margin-top: 10%;
    margin-left: 10%;
  }

  .policy_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80% !important;
    height: 15vh;
    padding: 5%;
    margin-left: 5%;
  }
  .policy_button {
    border-color: #007eff !important;
    color: #007eff !important;
  }
  .policy_symbol {
    height: 2.5vh !important;
  }
  .fact_check_card {
    width: 100% !important;
    height: 80vh !important;
    padding: 20px;
  }
  .fact_check_title {
    width: 100% !important;
    font-size: 2.5vh !important;
  }
  .policies_text {
    height: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .policies_text_size {
    font-size: 3vh !important;
  }
  .fact_check_details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60vh;
  }
  .custom-rectangle-card {
    display: flex;
    justify-content: space-between;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }

  .fact_check_text {
    font-size: 2.5vh !important;
    padding: 4px;
  }
  .about_us_check_icon {
    width: 30% !important;
  }
  .custom-square-card {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center !important;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    height: 25vh !important;
    width: 87vw;
    justify-content: space-evenly;
  }
  .fact_check_categories {
    margin-top: 40px !important;
    height: auto !important;
  }
  .see_all_stories_box {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .see_all_stories_button {
    margin-bottom: 20px;
  }
  .donation-and-text-small-card {
    flex-direction: column-reverse !important;
    display: flex;
    gap: 1vh;
  }
  .youturn-articles-text {
    width: 70vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -17px;
    margin-top: 20px;
  }

  .youturn-articles-text-second {
    width: 90vw !important;
    height: 4vh;
    font-size: 3.5vh !important;
  }
  .youturn-articles-first-text {
    /* font-size: 1.5vh !important; */
    font-family: Lato;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .fact_check_button {
    display: none !important;
  }

  .exposing-fakenews-eye-icon {
    color: red;
    font-size: 2vh;
  }
  .youturn-article-section {
    border-radius: 10px;
    overflow: hidden;
    margin-left: -39px !important;
  }
  .article-card-responsive-first {
    display: none;
  }
  .article-text-heading-content {
    padding: 0px !important;
  }
  .report_share_card {
    margin-top: 5px;
  }
  .single_article_page_text {
    width: 100% !important;
    padding: 3% 3% 3% 3%;
    /* height: 75%; */
    /* height: 80vh; */
  }
  .article_page_thumnail_image_with_text {
    height: 90vh;
    /* height: 112vh; */
  }
  .copyright-big-screen {
    display: none !important;
  }
  .article_list_news_stories_card {
    display: none;
  }
  .article_list_in_list_of_article {
    width: 80%;
    display: flex;
    gap: 4vh;
    flex-direction: column;
  }
  .custom_fact_check_views_and_icon {
    color: #e84756;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 6px; */
    margin-bottom: auto;
    margin-top: 5px;
  }
  /* .fact-check-third-div{
    background-color: #140f2d;
  } */
  .fact-check-first-div {
    background-color: #140f2d !important;
  }
  .fact-check-fourth-div {
    /* position: absolute !important; */
    background-color: #140f2d !important;
    height: 35vh !important;
  }
  .fact-check-space-between {
    display: flex;
    flex-direction: column-reverse;
    padding: 1vh;
  }
  .top-content-fact-check {
    height: auto !important;
  }

  .single-fact-check-title {
    line-height: 3vh !important;
    /* height: 17vh; */
    height: auto;
    font-size: 2vh;
    font-weight: bold !important;
    overflow: hidden;
    /* text-align: justify !important; */
  }
  .report-icon-image {
    color: black;
  }
  .report-share-icon-div {
    display: none;
  }
  .you-turn-logo-row {
    width: 80vw;
    display: flex;
    align-items: center;
  }
  .fact-check-you-turn-logo-col {
    width: 20vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fact-check-date-views-col {
    width: 60%;
    height: 6vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .single-fact-check-report-card {
    display: none;
  }
  .single-fact-check-report-share-div {
    /* padding: 3vw; */
    display: flex;
    justify-content: center;
  }
  .eye-image-div {
    display: none;
  }
  .you-turn-logo-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 12vh;
  }
  .single-fact-check-you-turn-logo {
    width: 15vw !important;
    height: 15vw !important;
    border-radius: 50%;
    object-fit: cover;
  }
  .close-division {
    margin-left: 0px;
  }

  .views-and-reading-time {
    width: 28.5vw !important;
  }
  .cards-two {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    margin-top: 20px !important;
  }
  .divider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_result_cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    gap: 5vh;
    width: 100%;
  }
  .load_more_button {
    /* width: 80%; */
    padding-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videos_page_load_more_button {
    width: 100% !important;
    height: 10vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .load_button_color {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  .video_load_button_color {
    width: 89vw !important;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .trending-now-arrow-image {
    display: none;
  }
  .list_of_article_page_news_article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .trending-now-arrow-with-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 5vh;
  }
  .list_of_article_trending_now_text {
    width: 100%;
    /* height: 170vh !important; */
  }
  .youturn-small-header-card {
    /* margin-top: -21px; */
    /* border-radius: 20px !important; */
    /* width: 103vw !important; */
  }
  .you_turn_video_card_image {
    /* height: 250px !important; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* width: 423px !important; */
    border-radius: 22px !important;
    object-fit: cover;
  }
  .youturn-videos-text-btn {
    width: 90vw;
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .see-all-btn-videos {
    color: white !important;
    border: 2px solid whitesmoke;
  }
  .article_top_image_skeleton {
    width: 80vw !important;
    height: 30vh !important;
    border-radius: 10px;
  }
  .article_featured_story_skeleton {
    height: 8vh !important;
    width: 79vw !important;
  }
  .article_recent_title_skeleton {
    height: 8vh !important;
    width: 79vw !important;
    margin-top: 20px;
  }
  .article_recent_sub_title {
    height: 8vh !important;
    width: 79vw !important;
    margin-top: 20px;
  }
  .fact-check-top-image-skeleton {
    /* width: 100%; */
    height: 30vh !important;
    width: 80vw !important;
  }
  .fact-check-and-article-categories-list {
    /* width: 100%;
    display: flex;
    flex-wrap: wrap; */
    display: none;
  }

  .laptop-join-us-card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 30vh; */
    margin-top: 4vh;
  }
  .icon-transition {
    width: 42px;
    height: 22px;
    transform: width 2s ease;
  }
  .content-dark {
    background-color: #1f1f1f !important;
    color: white !important;
  }
  .dark-mode-text {
    color: white !important;
  }
  .article_list_page_thumnail_image_with_text {
    height: 106vh;
  }
  .contactus_socialmedia_card_box {
    margin-top: 40px;
    height: 20vh;
  }
  .contact-info {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 15vh;
    justify-content: space-evenly;
  }
  .contact-card-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }
  .small_video_card_paly_button {
    position: absolute;
    z-index: 2;
    top: 20px;
    width: 310px;
    height: 110px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .rectangle_small_card_mobile_view_landing_page {
    width: 90%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-top: 44px; */
  }
  .single-fact-check-comment-list {
    border: 1px solid #6f6f6f;
    width: 100%;
    border-radius: 10px;
    padding: 2vh;
    background-color: #ffffff;
    overflow: auto;

    /* height: 18vh !important; */
  }

  .single-fact-check-comment-profile-image {
    width: 30%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .laptop-text-comment {
    width: 10vw;
    border-radius: 60%;
    overflow: hidden;
    height: 5vh;
  }

  .single-fact-check-reply-comment-text-comment {
    width: 11vw;
    border-radius: 60%;
    overflow: hidden;
    /* background-color: #0065cc; */
    height: 4vh;
  }
  .single-fact-check-comment-content {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .single_fact_check_comment_name {
    align-items: flex-start;
    font-size: 1.5vh !important;
  }
  .single-fact-check-comment-mobile {
    font-size: 1.2vh;
  }
  .single-fact-check-comment-content-row {
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .single-fact-check-reply-comment-text {
    font-size: 1.5vh;
  }

  .single-fact-check-comment-reply {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .single-fact-check-comment-reply {
    display: none;
  }
  .single-fact-check-reply-image-text {
    width: 6vw !important;
  }

  .laptop-post-input {
    border-top: none;
    border-right: none;
    border-left: none;
    outline: none;
    width: 91vw;
    font-size: 10px;
    font-weight: bold;
    -webkit-text-size-adjust: none !important;
  }
  .fact-check-button {
    width: 100px;
    height: 29px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: "none";
    border-bottom: none;
    border-right: none;
    background-color: gray;
    border-radius: 4px;
  }

  .reply-comment {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid gray;
    outline: none;
  }
  .single_fact_check_comment_name_date {
    font-size: 1.5vh !important;
  }
  .single-fact-check-child-reply-comments-text {
    font-size: 1vh !important;
  }

  .single_fact_check_comment_title {
    font-size: 2.8vh;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5vh;
  }
  .mobile-view-profile-image {
    width: 7vw;
  }
  .single-fact-check-mobile-view-profile-image {
    width: 7vw;
  }
  .landing_user_contribution {
    display: none !important;
  }
  .top_contributors_text {
    margin-top: 10px;
  }
  .bottom_card_language_english {
    font-size: 20px !important;
  }
  .new_you_turn_article_card {
    display: flex !important;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    margin-bottom: 20px;
  }
  /* .simple-grid {
    margin-top: 12vh !important;
  } */
  .article_page_bread_crumbs {
    display: none;
  }
  .article_page_thumbnail_image_with_title_subtitle {
    /* height: 100vh; */
    /* height: 100% !important; */
    display: none;
  }
  .article_page_stay_connected {
    /* width: 100%; */
    display: none !important;
  }
  .custom_social_media {
    width: 22vw;
    height: 13vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .single-fact-check-sign-up-row {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-sign-up-col {
    width: 100%;
  }

  .single-fact-check-sign-up-model {
    display: flex;
    flex-direction: "row";
    justify-content: center;
    align-items: center;
    width: 80vw;
  }

  .sign-in-cancel-button {
    background-color: white !important;
    color: #241a30 !important;
    border-radius: 6px;
    border: 1px solid #241a30 !important;
  }

  .sign-in-button {
    background-color: #241a30 !important;
    color: white !important;
    border-radius: 6px;
  }
  .fact-check-top-box {
    width: 95%;
  }

  .single-fact-check-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }

  .social_media_image_with_name {
    width: 20vw;
    height: 17vh;
  }
  .media_name {
    margin-top: 25px;
  }
  .single_article_page_related_fact_check {
    padding: 0 0 9% 0;
    font-size: 2.4vh !important;
    line-height: 30px !important;
  }

  .you-tube-views-text {
  }
  .youtube-youtube-videos-text {
    margin-left: -16px !important;
  }
  .views-full-box {
    height: 8vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .yt-subscribe-icon {
    margin-left: 17px;
  }
  .bottom_drawer {
    width: 100%;
  }
  .trending_now_article_list {
    padding: 5% 0 0 7%;
  }
  .article_list_recently_added {
    height: 125vh;
  }
  .user_contribution_text_skeleton {
    height: 10vh !important;
    width: 80vw !important;
    /* margin-top: 2vh; */
  }
  .user_contribution_title_skeleton {
    width: 80vw !important;
    height: 9vh !important;
    margin-top: 1vh !important;
  }
  .user_contribution_subtitle_skeleton {
    width: 80vw !important;
    height: 15vh !important;
    margin-top: 1vh !important;
  }
  .six-categories-card-second-div-for-skeleton {
    width: 80vw !important;
    height: 20vh !important;
    /* display: flex;
     justify-content: center; */
  }
  .fist_half_for_vertical_card_skeleton {
    width: 85vw !important;
    height: 25vh !important;
    margin-left: 1.5vh !important;
  }
  .fact_check_vertical_card_skeleton {
    display: none !important;
  }
  .bread-crumb-div {
    margin-left: 2vh !important;
    height: 8vh;
    margin-top: 2vh;
    display: flex;
    align-items: center;
  }
  .party_wise_small_card_skeleton {
    width: 80vw !important;
    height: 10vh !important;
    /* margin-left: 3vh; */
  }

  .single-fact-check-rating-text {
    font-size: 1.8vh;
  }
  .swiper-slide {
    /* overflow: hidden; */
    width: 200px !important;
    height: 230px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 13.2px;
  }
  .padding-swiper {
    padding: 10px;
  }
  .swiper-slide-card {
    background-color: red;
  }
  .swiper-slide-image {
    width: 100%;
    height: 110px;
    object-fit: cover;
  }
  .card-info-container {
    display: flex;
    flex-direction: column;
    background-color: green;
    height: 100px;
    justify-content: space-between;
  }
  .card-info-container-meta-data-second {
    padding: 5px;
    background-color: rgb(25, 131, 223);
    height: 35px;
  }
  .card-info-container-meta-data-first {
    padding: 5px;
    background-color: aliceblue;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .name-and-date-container {
    height: auto;
  }
  .text-card-slide {
    font-size: 10px;
  }
  .mobile_search_bar {
    width: 100%;
    height: 10vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black !important;
  }
  .search_result_bread_crumbs {
    padding-bottom: 3%;
  }

  .search_bread_crumbs_with_topic {
    width: 90%;
    padding-bottom: 3%;
  }
  .search_result_header_content {
    width: 100%;
    height: auto;
    padding: 4% 0 0 8%;
  }
  .search_result_topic {
    display: flex;
    flex-direction: column;
    gap: 3vh;
  }
  .search_text {
    font-weight: 700 !important;
  }
  .search_input_and_button {
    display: none;
  }
  .desk_menu_select_drop_down {
    width: 30vw;
  }
  .selector_and_divider {
    height: 15vh;
    padding: 6% 0 0 8%;
  }
  .button_divider {
    width: 90%;
  }
  .search_result_news_story_card {
    display: none;
  }
  .search_result_trending_now {
    width: 100%;
    gap: 5vh;
    align-items: center !important;
    padding-bottom: 15%;
  }
  .search_result_rectangle_small_card {
    width: 85%;
  }
  .search_result_social_media_sticky {
    display: none;
  }
  .search_results_items {
    width: 100%;
    padding-top: 5%;
    height: auto !important;
  }
  .search_result_recently_added_first_card {
    width: 100%;
    padding-top: 8%;
  }
  .search_result_recently_added {
    display: none;
  }
  .recently_added_carousel {
    width: 55vw;
    height: 45vh;
    border-radius: 10px;
    margin-left: 5px;
    /* min-height: 40vh; */
  }
  .recently_added_thumbnail_image {
    width: 55vw;
    height: 15vh;
    border-radius: 10px 10px 0 0;
  }
  .search_result_name_and_view_icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 2vh 2vh 0 2vh;
  }
  .search_result_date_and_clock_icon {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 0 2vh 2vh 2vh;
  }
  .search_result_view_eye_icon {
    width: 4vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;

    /* margin-top: 1px; */
  }
  .search_result_view_count_text {
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search_result_reading_time_text {
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recently_added_author_name_and_date {
    width: 60vw;
    height: 4vh;
    align-items: center !important;
    display: flex;
    justify-content: flex-start;
  }
  .recently_added_title {
    width: 100%;
    padding: 0 2vh 2vh 2vh;
    overflow: hidden;
    height: 17vh;
  }
  .search_result_view_and_clock_icon_div {
    width: 40vw;
    justify-content: flex-end;
  }
  .carousel-recently-added {
    width: 100%;
    height: 55vh;
    padding-bottom: 10%;
  }
  .search_result_news_article {
    display: flex;
    gap: 5vh;
    flex-direction: column;
  }

  .search_result_recently_added_carousel {
    /* padding-top: 1.5vh; */
  }
  .see_all_button_categories {
    width: 231px !important;
  }
  .videos-row-class {
    height: 28vh;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #140f2d;
  }
  .videos-top-image-contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .videos-top-image {
    height: 41vh !important;
    width: 90vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .videos_list_in_you_turn {
    display: none;
  }
  .videos-top-box {
    width: 90%;
    height: 12vh;
  }

  .you_turn_videos_story_card {
    width: 100%;
    height: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top-content-videos {
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
  }
  .video-title-text {
    font-size: 5vh;
    line-height: 4vh;
  }
  .you_turn_videos_card {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
  .you_turn_videos_list_stories_card {
    gap: 40px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .videos_small_video_card {
    border-radius: 10px 0 0 10px;
  }

  .videos_page_card {
    border-radius: 6px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
    width: 90vw;
    height: 20vh;
  }
  .videos_page_card_image {
    border-radius: 10px 10px 0 0;
    object-fit: contain;
  }

  .you-turn-videos-card-image-first-div {
    position: relative;
  }

  .youtube-logo-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .youtube-logo {
    width: 14vw !important;
  }
  .video_page_card_content {
    display: none;
  }
  .video_page_mobile_card_content {
    width: 40vw;
    height: 20vh;
    padding: 2vh;
    color: #232323;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    overflow: hidden;
  }
  .youtube-page-views-text {
    font-size: 1.5vh;
    line-height: 2vh !important;
  }

  .videos-page-recently-added-div {
    height: 90vh !important;
  }
  .bread_crumbs_div {
    display: none;
  }
  .user_contribution_top_half {
    /* background-color: red; */
    padding-top: 4vh !important;
  }
  .list_top_content_div {
    /* background-color: #05ae0e; */
    width: 90vw !important;
  }
  .bread_crumbs_and_top_content_div {
    padding: 3vh !important;
  }
  .recently_added_and_small_card_div {
    display: none !important;
  }
  .user_contribution_donation_card_div {
    display: none !important;
  }
  .user_contribution_recently_added_mobile_view {
    /* background-color: red; */
    /* padding-top: 4vh !important; */
  }
  .user_contribution_recently_added_first_row {
    height: auto !important;
    display: flex;
    margin-top: 1vh !important;
    padding: 2vh !important;
  }
  .recently_added_top_view_div {
    /* background-color: #05ae0e; */
    width: 90vw !important;
  }
  .user_contribution_donation_small_card_mobile_view {
    /* margin-top: 100px; */
    /* margin: 60% 0 0 0; */
    width: 100% !important;
    margin-top: 3vh !important;
    /* background-color: yellow !important; */
  }
  .user_contribution_recommended_stories_mobile_view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 5vh;
  }
  .user_contribution_trending_stories {
    display: none;
  }
  .inside_news_card {
    /* width: 33vh !important;
    background-color: RED; */
  }
  .user_contribution_social_media_list_mobile_view {
    /* margin-top: 3vh; */
    display: none !important;
  }
  .user_contribution_top_contributors_lap_view {
    display: none !important;
  }
  .user_contribution_all_stories_mobile_view {
    /* padding-top: 2vh;
    padding-left: 3vh; */
  }
  .user_contribution_all_stories_mobile_view_second_div {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
    margin-top: 3vh;
  }

  .donation-input-div {
    /* width: 72vw !important;
    height: 6vh;
    display: flex; */
    display: none;
    /* justify-content: center; */
  }

  .donate_button_fact_check {
    flex-direction: column !important;
    width: 100%;
  }
  .donation-form-input {
    width: 84vw !important;
  }
  .mobile-donation-form {
    display: flex;
    justify-content: center;
    height: 8vh !important;
  }

  .videos-top-title-div {
    height: 15vh;
    width: 100%;
  }
  .video-page-title-text {
    font-size: 2.1vh !important;
    line-height: 3.2vh !important;
    color: black !important;
    /* height: 12vh; */
    margin-top: 2vh;
  }
  .you_turn_video_page_card {
    height: 4vh !important;
    width: 60vw;
    position: absolute;
    display: "flex";
    justify-content: "space-between";
    z-index: 2;
    margin-right: 30vw;
    margin-top: 10vh;
  }

  .video-round-icon {
    width: 13vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    height: 7vh;
  }

  .you-video-page-title-text {
    font-size: 1.5vh;
  }
  .youtube-views-and-time {
    font-weight: bold !important;
  }
  .videos-top-title {
    height: 12vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  /* .mobile-display-none {
    display: none;
  } */

  .sign-up-logo-image {
    width: 8vw !important;
  }
  .sign-up-title-div {
    height: 7vh;
    width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .user_profile_card {
    height: 15vh !important;
    width: 90vw !important;
    background: linear-gradient(147.32deg, #140f2d 40.85%, #f49d36 169.52%);
    padding: 3% 3% 3% 3%;
  }
  .user_profile {
    height: 6vh !important;
    width: 10vw;
    border-radius: 30px;
  }
  .user_profile_with_name {
    height: 6vh;
  }
  .user_profile_title_card {
    height: 10vh;
    padding: 4% 0 0 5%;
  }
  .user_profile_card_division {
    height: 20vh;
  }
  .user_contribution_six-categories-card-second-div {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 5vh;
    padding: 3% 0 0 0;
    height: 26%;
  }
  .user_contribution_six-categories-card-first-div {
    display: flex;
    width: 100%;
    justify-content: center;
    height: fit-content;
    margin-top: 4vh;
  }
  .login_first_div {
    width: 100% !important;
    display: flex;
    align-items: center;
    height: 100%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    justify-content: center;
  }
  .login_second_div {
    border-color: #fff;
    background: linear-gradient(to right, #c33764, #1d2671) !important;
    display: flex;
    justify-content: center;
    height: 55vh;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    width: 90%;
    align-items: center;
    border-radius: 1vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  }
  .login_title_div {
    position: absolute;
    top: 26vh;
    margin-left: 2vh;
  }
  .login_form_row {
    /* width: 500px; */
  }
  .login_form_first_div {
    width: 100% !important;
  }
  .login_form_first_col {
    padding-left: 4vh !important;
    padding-right: 5vh !important;
  }
  .login_button_div {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 2vh !important;
    /* width: 100vw !important; */
  }

  .awards_and_recognition_landing {
    font-size: 2.5vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .user_article_count {
    width: 50vw;
  }
  .user_details_first_division {
    height: 50vh;
    flex-direction: column !important;
    justify-content: space-between;
  }
  .user_details_card {
    width: 90vw !important;
    height: auto;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    gap: 3.8vh;
    padding-bottom: 8%;
  }

  .user_profile_book_mark_card {
    height: 28vh;
    width: 100% !important;
    padding: 0 3% 0 3%;
  }
  .profile_card_title_with_icon {
    height: 5vh;
    align-items: center;
    padding: 0 4% 0 4%;
  }
  .user_article_or_fact_check_count {
    height: 7vh;
    width: 40%;
  }
  .icon_out_line {
    height: 20px !important;
    width: 20px !important;
  }
  .carousel_recently_added {
    display: flex;
    margin-bottom: 10%;
    /* justify-content: center; */
  }
  .forgot_password_text {
    color: white !important;
  }
  /* .r-flex-13awgt0 {
    flex: none !important;
  } */
  .over_flow_div {
    /* overflow-x: hidden !important; */
    overflow: hidden !important;
  }
  .edit-profile-bread-crumbs-div {
    background-color: #140f2d;
    width: 100%;
    height: 14vh;
    display: flex;
    justify-content: center;
  }
  .edit-profile-bread-crumbs-title-div {
    width: 86%;
    height: 14vh;
  }
  .edit-profile-profile-title {
    font-size: 3vh;
    font-weight: bold;
    color: white;
  }

  .edit-profile-bread-crumbs-row {
    width: 100%;
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .edit-profile-update-delete-full-div {
    width: 86%;
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .edit-profile-update-delete-div {
    width: 100%;
    height: 11vh;
    border-radius: 10px;
    background: linear-gradient(
      147.32deg,
      #140f2d 40.85%,
      #f49d36 169.52%
    ) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .edit-profile-pic-div {
    width: 20vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px !important;
  }

  .edit-profile-update-pic-div {
    width: 30vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-delete-pic-div {
    width: 30vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-profile-image {
    width: 15vw !important;
  }

  .edit-profile-text {
    color: white;
    margin-left: 1vw;
    font-size: 1.2vh !important;
  }
  .change-password-text {
    color: white;
    margin-left: 1vw;
    cursor: pointer;
    font-weight: bold;
  }

  .login_button {
    background-color: #241a30 !important;
    color: white !important;
    border-radius: 6px;
    width: 32vw !important;
  }
  .forgot_password_first_div {
    width: 100% !important;
    display: flex;
    align-items: center;
    height: 100%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .forgot_password_second_div {
    background: linear-gradient(to right, #c33764, #1d2671);
    color: #fff;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 37vh;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    width: 90%;
    align-items: center;
    border-radius: 1vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  }
  .forgot_password_input {
    width: 30vh !important;
  }
  .forgot_password_title_div {
    position: absolute;
    top: 34vh;
    margin-left: 2vh;
  }

  .donation_card_amount {
    height: 20px;
    width: 30vw;
  }
  .user_claim_donation {
    width: 90%;
    overflow-y: hidden;
  }
  .edit-user-profile-mobile-input {
    width: 80vw !important;
    height: 5vh !important;
  }

  .edit-profile-input-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 90vw;
  }
  .you_turn_logo_image_div {
    position: absolute;
    top: 27vh;
    left: 4vw;
    /* margin-left: 4vh; */
    margin-top: 5vh !important;
  }
  .forgot_password_submit_button {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 3vh;
  }
  .forgot_submit_button {
    border-color: #fff;
    background: linear-gradient(to right, #c33764, #1d2671) !important;
    border-radius: 6px;
    width: 31vw;
  }
  .login_turn_logo_image_div {
    position: absolute;
    top: 24vh;
    left: 21vw;
  }
  .reset_password_div {
    position: absolute;
    left: 14vh;
    top: 26vh;
  }
  .reset_turn_logo_image_div {
    position: absolute;
    top: 24vh;
    left: 8vw;
    margin-left: 6vh;
  }

  .reset_password_submit_button {
    border-color: #fff;
    background: linear-gradient(to right, #c33764, #1d2671) !important;
    color: white;
    border-radius: 6px;
    width: 31vw;
  }
  .reset_password_form_row {
    /* width: 36vh !important ; */
  }
  .reset_password_button_div {
    display: flex;
    justify-content: center;
    gap: 10px;

    margin-top: 3vh;
  }
  .fact_categories_name {
    display: flex;
    align-items: flex-start;
    margin-left: 7%;
    /* margin-top: 200px; */
  }
  .funding_component {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 470px;
  }
  .funding_description_responsive {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .statements_button {
    width: 100%;
    height: 45px;
    font-size: 15px;
    color: #007eff;
    border-color: #007eff;
    margin-bottom: 15px;
    border-radius: 10px;
    font-family: Lato;
  }
  .meet_team_heading {
    width: 91%;
    display: flex;
    justify-content: start;
    margin-right: 20px;
    margin-left: 20px;
  }
  .fight_against_fake_news_card {
    display: none;
  }
  .funding_paragraph_text {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .statements_full_button {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .award_and_recognition_laptop {
    display: none;
  }
  .about_us_social_media {
    margin-top: 100px;
  }
  .about_us_fact_categories_name {
    font-size: 2.5vh;
  }
  .article_list_categories {
    width: 100% !important;
  }
  .donation-content-small-card {
    color: white;
    /* text-align: justify; */
  }

  .donation-content-small-card-text {
    color: white;
    font-size: 2vh !important;
    /* text-align: justify; */
    /* padding: inherit; */
  }
  .user_contribution_recently_added_carousel {
    /* width: 100% !important; */
    margin-top: 3vh !important;
  }
  .user_contribution_recently_added_first_div {
    width: 100% !important;
  }
  .top_contributors_mobile_view {
    width: 100%;
  }
  .count_circle_out_lined {
    width: 20vw;
    padding-bottom: 4%;
  }
  .carousel-top_contributors_mobile_view {
    width: 100%;
    height: 100%;
    background-color: red;
  }
  .user_contribution_top_image_skeleton {
    width: 85vw !important;
    height: 30vh !important;
    border-radius: 10px;
    margin-left: 1vh;
  }
  .categories_see_all_button_divider {
    display: none !important;
  }
  .see_all_button_mobile_view {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fact-check-list-news-story-card-skeleton {
    display: none;
  }
  /* .mobile_drawer_content {
    font-size: 16px !important;
    width: 90px !important;
    margin-left: 10px !important;
  } */

  .mobile-sign-in-button {
    width: 50px !important;
    height: 20px !important;
    font-size: 10px !important;
    margin-left: 16px !important;
    background-color: rgba(20, 15, 45, 1);
    border: 1px solid white;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .drawer_close_icon {
    height: 18px !important;
    width: 18px !important;
    margin-left: 54px !important;
  }

  .social_media_icons_first_row {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .header-drawer-social-media-second-div {
    width: 86% !important;
    display: flex;
    justify-content: center;
  }

  .videos-page-story-div {
    width: 90%;
    gap: 5vh !important;

    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .you-turn-videos-card {
    height: 40vh;
    border-radius: 10px;
    box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15);
  }
  .you-turn-videos-card-title-div {
    height: 4vh;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .you-turn-videos-card-image-second-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .you-turn-videos-card-views-div {
    height: 2vh;
    width: 95%;
  }
  .user_profile_load_button_color {
    width: 90%;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: black;
  }

  .article_list_carousal {
    padding: 8% 0 6% 5%;
    width: 100%;
  }
  .article_list_mobile_carousal {
    width: 100%;
  }
  .back_arrow {
    display: none;
  }
  .form-container sign-in {
    background: linear-gradient(to right, #c33764, #1d2671) !important;
  }
  .container form {
    background: linear-gradient(to right, #c33764, #1d2671) !important;
  }
  .sing_in_text {
    color: white !important;
    display: flex;
    justify-content: center;
  }
  .sing_in_text_underline {
    color: white !important;
    text-decoration: underline !important;
    font-weight: bolder;
  }
  .not_register_text {
    color: white !important;
  }
  .landing_submit_button {
    width: 72vw !important;
  }

  .featured-story-text {
    font-size: 3vh !important;
    line-height: 3vh !important;
  }
  .videos_list_news_story_card {
    width: 100%;
    height: auto;
  }
  .user_profile_bread_crumbs {
    padding-bottom: 2% !important;
  }
  .donation_details_no_data {
    font-size: 12px;
    padding: 5px;
  }

  .donation_details_no_data .ant-empty-image {
    align-items: center;
    height: 6vh;
  }
  .user_donation_details {
    width: 80%;
  }

  /* .article_ist_social_media_list {
    background-color: green !important;
    height: 100vh !important;
  } */

  .landing_top_contributers {
    display: none;
  }
  .top_contributors_mobile {
    display: flex;
    overflow: hidden;
    position: relative;
    margin-left: -10px;
  }
  .update-user-profile-buttons-full-div {
    width: 80vw !important;
    display: flex;
    justify-content: center;
  }

  .update-user-profile-buttons-div {
    width: 65% !important;
    display: flex;
    justify-content: space-between;
  }

  .edit_user_back_button {
    background-color: white !important;
    color: black !important;
    width: 25vw !important;

    border-radius: 10px;
    height: 4.5vh !important;
    border: 1px solid black !important;
  }
  .edit_user_save_button {
    width: 25vw !important;
    border-radius: 10px;
    height: 4.5vh !important;
  }

  .drawer_guest_div {
    height: 5vh;
    display: flex;
    flex-direction: row;
  }
  .user-and-sign-in-section {
    /* height: 7vh !important; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 90vw;
  }

  .drawer_profile_icon_div {
    width: 10vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }

  .drawer_profile_name_div {
    width: 50vw !important;
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
  .drawer_profile_button_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 94%;
    height: 8vh;
  }

  .chain-profile-div {
    display: flex;
    margin-left: 2.3vw;
  }

  .profile-image-size {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20%;
  }
  .article_list_headline {
    margin-top: 3vh !important;
    height: auto;
    cursor: pointer;
  }

  .related_article_button_color {
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }
  .user_profile_donation_details {
    height: 5vh;
  }
  .forgot_password_send_mail {
    margin: 1vh 4vh 1vh 6vh;
    color: white !important;
  }
  .privacy_policy_main_div {
    height: auto;
    margin: 1vh 7vh !important;
    overflow-x: hidden;
  }

  .social-media-logo {
    width: 12vw !important;
  }

  .article_title_size {
    font-size: 1.7vh !important;
    line-height: 2.4vh !important;
  }
  .fact_check_first_half_second_div {
    width: 90%;
  }

  .team_members_details {
    background-color: transparent;
    border-color: #ffb037;
    border-radius: 10px;
    /* margin-bottom: 4vh; */
    display: flex;
    margin: 2vh;
    /* flex-direction: column-reverse !important; */
  }
  .team_member_image {
    display: flex;
  }
  .team_members_details .team_member_image {
    flex-direction: column-reverse;
  }
  .team_members_details .team_member_name {
    width: 100%;
  }
  .team_members_details .member_description {
    margin-top: 20px;
  }
  .member_description {
    width: 100%;
  }

  .user_profile_donation_card {
    height: 23vh;
    width: 100% !important;
  }
  .user_profile_donation_date {
    width: 50vw;
  }
  .user_profile_claim_date_and_topic {
    width: 50vw;
  }
  .user_profile_card_div {
    width: 100%;
    padding: 3% 2% 3% 2%;
  }
  .user_profile_background_div {
    height: 190vh;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 5% 5%5% 5%;
  }

  .user_profile_details_first_div {
    height: 12vh;
    padding: 4% 0 0 6%;
  }
  .edit_user_profile_button {
    width: 12vh !important;
  }
  .donation_and_contribution_cards {
    padding-top: 8%;
    height: 58vh !important;
  }
  .landing_page_top_image {
    height: 29.3vh !important;
    width: 90% !important;
    border-radius: 10px;
    z-index: 1;
    /* margin-left: 9px; */
    margin-top: 21px;
    object-fit: cover;
  }
  .about_us_breadcrumbs {
    display: none !important;
  }
  .sign_up_text_mobile_view {
    /* margin-left: 3vh; */
    color: white !important;
    display: flex;
    justify-content: center;
    width: 100% !important;
  }

  .fact_check_category_modal_div {
    width: 88%;
    height: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .single-fact-check-rating-image-size {
    width: 30vw !important;
  }

  .custom_new_article_card_inside_div {
    background-color: rgba(251, 249, 249, 0.75);
    width: 89vw;
    padding: 2%;
    height: 10vh;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  .invite_button {
    max-width: 30vh;
    border-radius: 9px !important;
    border: transparent;
  }
  .custom-fact-check-card-first-half_for_landing_page {
    margin-top: 16vh;
    margin-left: -51px;
    width: 37vh !important;
    background-color: rgba(20, 15, 45, 0.9) !important;
    z-index: 2;
    position: absolute;
  }
  .hide_about_page_in_mobile {
    display: none !important;
  }
  .top_contributors_image_main_div {
    display: flex;
    flex-direction: row;
    gap: 2vh !important;
  }
  .carousal_for_top_contributors {
    width: 100%;
  }
  .fact_check_container {
    height: 4vh;
  }
  .fact_check_square_card_name {
    height: 7vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 4%;
  }
  .fact_check_square {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 110vh;
    padding: 5%;
  }
  .square-card-image {
    width: 100%;
  }

  .single-fact-check-whatsapp-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .single-fact-check-whatsapp-button-div {
    /* background-color:#140f2d; */
    background-color: #00704a;

    height: 7vh;
    width: 92%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }

  .single-fact-check-whatsapp-image-size {
    width: 10vw !important;
    cursor: pointer;
    display: flex;
  }

  .single-fact-check-whatsapp-text {
    font-size: 1.5vh;
    color: white;
    text-align: left;
    font-weight: 300;
    cursor: pointer;
  }

  .single-fact-check-whatsapp-second-button-div {
    /* width: 70vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hide-mobile {
    display: none;
  }

  .trending-stories-title > text {
    margin-bottom: 30px;
  }
  .user_contribution_stories_second_div {
    display: flex;
    flex-direction: column;
    gap: 3vh;
  }
  .your-custom-classname {
    background-color: transparent !important;
    /* color: white !important; */
    color: gray !important;
    /* width: 50vh !important;
    max-width: 44vh; */
    cursor: pointer !important;
    width: 40.5vw;
  }

  .categories-content {
    width: 80vw;
    height: 20vh;
    max-height: 90px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 15px;
    left: 48px;
    top: 38px;
    position: absolute;
    border-radius: 50px;
    border: 1px #140f2d solid;
    justify-content: flex-end;
    align-items: center;
    gap: 26px;
    display: flex;
  }
  .fact-check-category-text {
    width: auto;
    flex: 1;
    font-size: 1.2vh;
    word-wrap: break-word;
  }
  .category-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 13vh;
  }
  .category-title {
    font-size: 2vh !important;
  }
  .social-media-mobile-screen {
    width: 100% !important;
    height: 10vh;
  }
  .social_media_title_in_footer {
    width: 100%;
  }
  .signatory_logo_in_footer {
    width: 32vw !important;
  }
  .copyright-text-in-footer {
    text-align: center;
    height: 13vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .rectangle_small_card_first_half {
    height: 5.8vh;
    overflow: hidden;
  }
  .rectangle_small_card_second_half {
    height: 4vh;
    /* background-color: red; */
    display: flex;
    /* justify-content: flex-end; */
    align-items: end;
  }
  .container {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 90%;
    min-height: 550px !important;
  }
  .submit_claim_file_upload_col {
    padding-left: 13px !important;
  }
  .inside_card_user_contribution {
    /* width: 245px !important; */
    width: 74vw !important;
    margin-top: 14vh !important;
    background-color: rgba(20, 15, 45, 0.9);
    z-index: 2;
    position: absolute;
  }

  .related-fact-check-title-div {
    height: 5vh;
    overflow: hidden;
  }
  .submit_your_claim_title_and_content {
    width: 88vw;
    height: auto;
  }
  .submit_your_claim_title_div {
    width: 88vw;
    padding: 0 0 4% 0;
  }
  .screen-reader {
    display: none !important;
  }
  .otp_screen_second_div {
    display: contents !important;
  }

  .donation_card_bottom_text {
    line-height: 18px !important;
  }
  .recently_added_font_size {
    width: 90%;
    font-size: 2.4vh !important;
    line-height: 30px !important;
  }
  .footer-social-media {
    display: flex;
    gap: 5px;
  }
  .article_first_card_mobile {
    height: auto;
    position: relative;
    z-index: 2;
    padding: 0 3% 0 3%;
  }

  /* .background_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
    background-color: #140f2d;
    z-index: 1; 
  } */

  .single-fact-check-title-small-div {
    /* height: 15vh !important; */
    overflow: hidden !important;
    height: auto;
  }

  .donation-small-card-fight-text {
    font-size: 2.5vh !important;
  }

  .skeleton-single-fact-check-top-image-col {
    height: 20vh !important;
    width: 90vw !important;
  }

  .skeleton-fact-check-bread-crumbs-col {
    height: 21vh !important;
    width: 90vw !important;
    margin-top: 1vh !important;
  }

  .skeleton-fact-check-rating {
    height: 21vh !important;
    width: 90vw !important;
    margin-top: 1vh !important;
  }

  .fact_news_title_main_div {
    display: flex;
    width: 100%;
    align-items: center;
    /* padding: 2vh; */
    height: 10vh;
  }

  .contact_us {
    width: 100%;
    padding: 0 3% 0 3%;
  }
  .contact_title {
    padding: 5% 0 3% 0;
    font-size: 4vh !important;
  }
  .contact_us_donation_card {
    width: 100% !important;
    margin-top: 10px;
  }
  .contact_us_submit_button {
    width: 100%;
  }
  .feed_back_form {
    background-color: #140f2d;
    padding: 0 3% 0 3% !important;
  }
  .divider-feedback-msg {
    border-color: #e79432 !important;
    height: 1px;
  }
  .other_option_text {
    color: white;
    font-size: 1.5vh;
  }
  .form_title_for_contact_us {
    color: white !important;
  }

  .feedback-input {
    color: white;
  }
  .contact_us_text_area {
    background-color: transparent !important;
    color: white;
  }
  .edit_profile_input_field {
    border: none;
    box-shadow: none;
    width: 71vw !important;
  }
  .link_icon {
    font-size: 20px;
  }
  .search_tab {
    width: 50% !important;
  }
  .search_result_text {
    font-size: 23px !important;
    line-height: 30px !important;
  }
  .donation-input-amt-card {
    background-color: whitesmoke;
    width: 14vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .report_share_card_skeleton_mobile {
    height: 8vh !important;
    width: 89vw !important;
    margin-top: 1vh !important;
  }
  .funding-title {
    font-size: 3vh !important;
  }
  .exposing_fake_news_skeleton_container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .exposing_fake_news_skeleton {
    width: 90vw !important;
  }
  .exposing_fake_news_skeleton_first {
    width: 90vw !important;
    height: 25vh !important;
  }

  .single_article_image_skeleton {
    height: 26vh !important;
    width: 100% !important;
  }
  .single_article_view_skeleton_loading {
    width: 100% !important;
    height: 15vw !important;
    margin-bottom: 2%;
  }

  .single_article_top_text_mobile {
    padding: 3% 3% 0 3%;
  }
  .single_article_top_title {
    /* width: 100%;
    height: 8vh;
    padding: 4%;
    background-color: #140f2d; */
    display: none;
  }
  .support_us_first_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .support_us_title {
    /* height: 18vh; */
    padding: 0 5% 5% 3%;
  }
  .support_us_text {
    line-height: 30px !important;
    padding-top: 3%;
    font-size: 4vh !important;
  }
  .contribution_card_in_support_us {
    margin-top: 10px;
    width: 100% !important;
  }
  .join_us_button_contribute {
    width: 100% !important;
    border-radius: 9px;
    border: transparent;
  }
  .donation-card-small {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* justify-content: space-between; */
    /* height: 55vh; */
    height: auto;
  }

  .landing-donation-width {
    width: 99% !important;
  }

  .fact-check-row-class {
    display: none;
  }
  .carousel-container {
    display: flex !important;
    justify-content: center !important;
  }
  .team-carousel-item {
    display: flex;
    justify-content: center;
  }

  .fact_check_first_card_mobile {
    height: auto;
    position: relative;
    z-index: 2;
    /* padding: 0 3% 0 3%; */
    width: 92%;
  }

  .page-not-found-text {
    color: white;
    font-size: 6vh;
  }

  .page-not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    /* background-image: url(''); */
    background-size: cover;
    background-position: center;
  }

  .page-not-found-title {
    font-size: 4rem;
    font-weight: bold;
    color: #ff6347;
  }

  .page-not-found-content {
    margin-top: 20px;
    text-align: center;
  }

  .page-not-found-message {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }

  .page-not-found-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .page-not-found-button:hover {
    background-color: #0056b3;
  }

  .single-fact-check-mobile-top-second-div {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .browse_categories {
    line-height: 30px !important;
    font-size: 3vh !important;
  }
  .user-contribute-fact-div {
    width: 100% !important;
    background-color: #140f2d;
    padding: 8% 3% 8% 3%;
  }
  .user-contribute-text {
    color: white;
    font-size: 2vh !important;
    line-height: 3vh !important;
  }
  .donation-main-content {
    color: #f49d36;
    font-size: 4vh;
    font-weight: bold;
    line-height: 6vh !important;
    margin-top: 25px;
    font-family: Kufam;
  }

  .terms_and_conditions_mobile_view {
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 3% 3% 3% 3%;
  }

  .contact_us_text {
    margin-bottom: 3% !important;
    font-weight: 700 !important;
    font-size: 2.5vh !important;
  }
  .terms_and_condition_points {
    /* height: 24vh; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .terms_points {
    font-weight: 500 !important;
    line-height: 28px !important;
  }
  .terms_and_conditions {
    /* height: auto !important;
    padding: 5% 3% 3% 3%; */
    display: none;
  }
  .terms_and_conditions_text {
    font-size: 2.5vh !important;
    font-weight: 700 !important;
    margin-top: 3% !important;
    margin-bottom: 4%;
  }
  .new_articles_in_landing_page {
    display: none;
  }
  .skeleton-single-fact-title-col {
    height: 8vh !important;
    width: 90vw !important;
    margin-top: 1vh !important;
  }
  .single_article_title_skeleton {
    height: 8vh !important;
    width: 100% !important;
    margin-bottom: 3%;
  }
  .user_profile_titles {
    padding: 3% 0 4% 4% !important;
    font-size: 3vh !important;
  }
  .user_contribution_second_div {
    height: 87vh;
  }
  .user_contribution_recently_added_second_div {
    display: flex;
    flex-direction: row;
    height: 6vh;
    align-items: flex-start;
    margin-top: 30px;
  }
  .editor_image {
    width: 20vw !important;
    height: 20vw !important;
    border-radius: 50%;
    object-fit: cover;
  }
  .editor_profile_card {
    /* height: 50vh; */
    width: 100% !important;
  }
  .author_profile_background_div {
    height: auto !important;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 5% 5%5% 5%;
  }
  .author_profile_count_card {
    height: 23vh;
    width: 100% !important;
    flex-direction: column;
  }
  .editor_profile_inner_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5% 0 0 0;
  }
  .author_name_and_about {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4% 4% 4%;
  }
  .author_name {
    padding-top: 4% !important;
  }
  .author_social_media_image {
    width: 10vw;
  }
  .author_social_media_share {
    display: flex;
    gap: 7px;
  }
  .count_details_card {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .author_about_details {
    text-align: justify !important;
  }

  .single-fact-heck-content-align {
    text-align: justify !important;
  }
  .social_media_card {
    height: 23vh;
    width: 100% !important;
    flex-direction: column;
  }
  .author_face_book_image {
    width: 11vw;
  }

  .user_profile_load_more_button {
    display: flex;
    justify-content: center;
    height: 15vh;
    align-items: center;
  }
  .top_contributors_mobile_view_first_div {
    height: 9vh !important;
  }
  .header-stripe {
    display: none !important;
  }
  .search_result_no_data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
  }
  .search_result_join_us {
    margin-top: 10px;
  }

  /* .ant-input {
    color: #000000 !important;
  } */
  .feature-story-sub-title-content-user-contribution {
    height: 22vh;
    overflow: hidden;
  }
  input,
  textarea {
    touch-action: manipulation;
  }
  .no-zoom-input {
    -webkit-text-size-adjust: 100% !important;
  }
  .mobile_header_drawer {
    width: 80%;
    height: 100% !important;
    background: linear-gradient(166.4deg, #140f2d 9.74%, #f49d36 281.54%);
    overflow: hidden;
  }

  .become_member_mobile_view_user_contribution {
    display: none !important;
  }
  .landing_page_mobile_top {
    position: relative;
    height: 10vh;
  }
  .landing_page_mobile_top_container {
    background-color: #140f2d;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 0 20px 50px 30px #140f2d;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 6% 9% 0 9%;
  }
  .contact_us_bread_crumbs {
    display: none;
  }

  .landing_page_first_article {
    height: 55vh !important;
    width: 42vw !important;
  }
  .custom-breadcrumbs {
    display: none !important;
  }
  .mobile_bread_cream {
    display: none;
  }
  .user_contribution_bread_cr_div {
    display: none !important;
  }

  .donation-main-content {
    color: #f49d36;
    font-size: 4vh;
    font-weight: bold;
    line-height: 6vh !important;
    margin-top: 0px !important;
  }

  .user_contribution_join_div {
    height: 56vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .drawer_mobile_hide {
    /* display: none !important; */
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #140f2d !important;
  }
  .ant-carousel .slick-dots li button {
    background: #20658e;
  }
  .correction_policy_title {
    font-size: 19px !important;
  }
  .correction_policy_container {
    height: auto;
    width: 100%;
    padding: 3%;
  }
  .join_us_whatsapp_card {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .award_and_recognition_mobile {
    /* height: 38vh ; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fact_check_title_in_mobile {
    font-size: 4vh !important;
  }
  .user_contribution_mobile_title {
    font-size: 4vh !important;
  }

  .my_fact_check_container {
    padding: 0 4% 0 4%;
  }
  .my_fact_check_table {
    padding: 1% 3% 0 3%;
  }
  .my_article_container {
    padding: 2% 3% 0 3%;
  }
  .single_article_whatsapp_channel {
    padding: 5% 0 5% 0;
  }
  .share_container {
    display: flex;
    gap: 10px;
  }
  .article_share_icon {
    height: 25px;
  }
  .article_share_icons {
    height: 20px;
  }
  .copy_to_clipboard_icon {
    height: 25px;
    cursor: pointer;
  }
  .reset_otp_box {
    margin-top: 2vh;
  }
  .resent_otp_text {
    color: #1d6758;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
  .public_spirited_image_image {
    display: none;
  }
  .public_spirited_image_image_and_text_div {
    height: 374px;
    display: flex;
    width: 90%;
    justify-content: center;
    flex-direction: column;
  }

  .public_spirited_text_content {
    text-align: justify;
  }
  .videos_play_button_icon_mobile_view {
    width: 32px !important;
    height: 32px !important;
    margin-right: 20px !important;
  }
  .videos_youtube_icon_mobile_view {
    width: 32px !important;
    height: 32px !important;
    margin-left: 20px !important;
  }
  .subscribe_and_notification_icon_main_div_mobile_view {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 49px;
    align-items: baseline;
  }
  .search_result_load_more_button {
    min-width: 300px !important  ;
    max-width: 100px !important;
  }
  .article_mobile_background_container {
    background-color: #140f2d;
    height: 20vh;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .top_first_article_in_mobile {
    position: relative;
  }
  .no_data_for_category_wise_article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
