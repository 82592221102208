@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .single_article_top_details_wrapper {
    width: 90%;
    display: flex !important;
    gap: 2%;
  }
  .single_article_title_report_share_save_container {
    /* width: 30%; */
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    padding: 2% 0 2% 0;
  }
  .single_article_second_column_container {
    width: 70%;
  }
  .single_article_first_column_title {
    color: #ffffff;
    font-size: 25px;
    padding: 2% 0 0 0;
    height: 80%;
  }
  .single_article_first_column_container {
    width: 35%;
  }
  .single_article_writer_details {
    /* height: 40%; */
    display: flex;
    align-items: center;
  }
  .report_share_container {
    display: none;
  }
  .whatsapp_gif_icon_container {
    width: 15%;
  }
  .whatsapp_channel_content {
    width: 100%;
    font-size: 20px;
  }
  .single_article_content_container {
    width: 60%;
    gap: 30px;
  }
  .single_article_donation_container {
    width: 36%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  /* .single_article_donation { */
  /* height: 600px; */
  /* } */
  .single_article_icons {
    font-size: 18px;
  }
  .writer_name {
    font-size: 20px;
  }
  .published_date {
    font-size: 20px;
  }
  .social_media_card_container {
    gap: 25px;
    padding: 15% 0 15% 20%;
  }
  .join_whatsapp_channel_division {
    display: none;
  }
  .donation_card_mobile_view {
    display: none;
  }
  .writer_profile_image {
    width: 110px !important;
    height: 110px !important;
  }
  .single_article_first_column_title_ta {
    color: #ffffff;
    font-size: 25px;
    padding: 2% 0 0 0;
    height: 85%;
  }
  .report_container_second_column {
    font-size: 15px;
  }
  .single_article_top_icons {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .single_article_social_media_name {
    font-size: 20px;
  }
  .single_article_icons_wrapper {
    font-size: 18px;
  }
  .report_title {
    font-size: 35px;
  }
  .we_are_committed_text_ta {
    font-size: 18px;
  }
  .single_article_report_button {
    font-size: 15px;
  }
  .article-content {
    font-size: 20px;
  }
  .we_are_committed_text {
    font-size: 25px;
  }
}
