@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .single_article_top_details_wrapper {
    width: 95%;
    display: flex !important;
    /* flex-direction: column !important; */
    flex-direction: column-reverse !important;
    gap: 5%;
  }

  .single_article_second_column_container {
    width: 100%;
    padding-top: 8%;
  }
  .single_article_top_icons_container {
    display: none !important;
  }
  .single_article_first_column_container {
    width: 100% !important;
  }
  .single_article_writer_details {
    /* height: 100%; */
    display: flex;
    align-items: center;
  }
  .single_article_view_and_reading_time {
    display: none;
  }
  .writer_name {
    font-size: 18px;
  }
  .published_date {
    font-size: 18px;
  }

  .single_article_first_column_title {
    color: var(--background-theme-color);
    font-size: 22px;
  }
  .single_article_back_ground_container {
    height: 45% !important;
  }
  .report_share_second_column_container {
    width: 10%;
  }
  .report_share_container {
    padding: 5% 0 5% 0;
    width: 100%;
  }
  .single_article_donation_conatiner {
    display: none;
  }
  .single_article_content_container {
    width: 100% !important;
    gap: 30px;
  }
  .single_article_donation_container {
    display: none;
  }
  .report_container {
    display: none;
  }
  .whatsapp_gif_icon_container {
    width: 15%;
  }
  .whatsapp_channel_content {
    font-size: 20px;
  }
  .writer_profile_image {
    width: 80px !important;
    height: 80px !important;
  }
  .single_article_details_with_donation_wrapper {
    padding: 4% 0 3% 0 !important;
  }
  .single_article_first_column_title_ta {
    color: var(--background-theme-color);
    font-size: 22px;
  }
}
