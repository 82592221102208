@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .related_recent_fact_check_title_container {
    height: 50px;
  }
  .recent_fact_check_title_text {
    font-size: 15px !important;
    line-height: 21px !important;
  }
  .single_fact_check_claim_container {
    position: sticky !important;
    top: 180px;
    bottom: 10px;
  }

  .recent_fact_check_title_text_ta {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .reply_comment_text {
    font-size: 15px;
  }
  .donation_first_row_content_en {
    font-size: 20px;
    line-height: 25px !important;
  }
  .whatsapp_channel_text {
    font-size: 20px;
    line-height: 20px;
  }
}
