@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100&display=swap");

:root {
  --primary-color: #0065cd;
  --table-header-bg: #013366;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
  --bread-crumbs-color: #140f2d;
  --reading-tag: #0065cc;
  --award-content: rgba(255, 255, 255, 0.8);
  --uib-size: 21px;
  --uib-speed: 2.5s;
  --uib-color: #ffffff;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Kufam";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Kufam-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Kufam-italic";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Kufam-Italic-VariableFont_wght.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Lato_Bold";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Lato-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

/* basic styles start*/
.text-white {
  color: rgb(1, 1, 1) !important;
}
.center-text {
  text-align: center !important;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
.icon-style {
  width: 20px;
  height: 20px;
}

/* basic styles end*/

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  overflow-x: hidden;
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.sidebar-menu {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
  width: 240px;
  background-color: #013366 !important;
}

.ant-menu-inline-collapsed {
  width: 80px !important;
}

.sidebar-menu .ant-menu-sub {
  background-color: #013366 !important;
}

.sidebar-menu .ant-menu-submenu > .ant-menu {
  max-height: 2000px !important;
  overflow: hidden;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item-active {
  background-color: transparent !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item .ant-menu-title-content {
  color: #038fdd !important;
  text-decoration: none !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item
  .ant-menu-title-content
  a {
  color: #038fdd !important;
  font-family: "Roboto" !important;
  text-decoration: none !important;
}

.sidebar-menu:not(.ant-menu-horizontal) .ant-menu-item .ant-menu-item-icon {
  color: #038fdd !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-title-content {
  color: #038fdd !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-title-content
  a {
  color: #038fdd !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu:not(.ant-menu-submenu-selected)
  .ant-menu-submenu-title
  .ant-menu-item-icon {
  color: #038fdd !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-title-content {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-title-content
  a {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-menu-item-icon {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-title-content {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-title-content
  a {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-item-active
  .ant-menu-item-icon {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-open
  .ant-menu-submenu-title[aria-expanded="true"]
  .ant-menu-title-content {
  color: white !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-open
  .ant-menu-submenu-title[aria-expanded="true"]
  .ant-menu-item-icon {
  color: white !important;
}

.ant-tooltip-arrow::before {
  background-color: transparent !important;
}

.ant-tooltip {
  background-color: #013366 !important;
  margin: 0;
  padding: 0;
}

.ant-tooltip-inner {
  background-color: transparent !important;
}

.ant-tooltip-inner a {
  color: #038fdd !important;
}

.ant-tooltip-inner a:hover {
  color: #fff !important;
}

.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  background-color: #042446 !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content
  a {
  color: #fff !important;
}
.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-item-icon {
  color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before {
  background-color: #fff !important;
}

.sidebar-menu:not(.ant-menu-horizontal)
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-color: #fff !important;
}

.sidebar-menu .ant-menu-item::after {
  border-right: 0px !important;
}

.sidebar-menu .ant-menu-submenu-selected .ant-menu-item-icon {
  color: white !important;
}

.ant-menu-submenu-popup .ant-menu-sub {
  background-color: white !important;
}
/* .ant-menu-submenu-popup .ant-menu-sub:hover {
  background-color: gray !important;
  color: black !important;

} */

.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-submenu-popup .ant-menu-item-active {
  background-color: transparent !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content a {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-title-content {
  color: black !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-title-content a {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item-active .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-submenu-active .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content {
  color: #fff !important;
}
.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-title-content
  a {
  color: #fff !important;
}
.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-item-icon {
  color: #fff !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-arrow::before {
  background-color: #fff !important;
}

.ant-menu-submenu-popup
  .ant-menu-submenu-active
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background-color: #fff !important;
}

.ant-menu-submenu-popup .ant-menu-item::after {
  border-right: 0px !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  width: 100% !important;
}

.ant-menu-vertical {
  max-height: 20000px !important;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title {
  margin: 4px 0px;
  padding: 0px calc(50% - 20px / 2) !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
  margin: 4px 0px;
  padding: 0px calc(50% - 20px / 2) !important;
}

.sidebar-menu .ant-menu-item-icon {
  font-size: 20px !important;
}

/* 
.sidebar-menu .ant-menu-submenu-title{
  padding-left: 40px !important;
}

.sidebar-menu .ant-menu-item{
  padding-left: 40px !important;
}

.sidebar-menu .ant-menu-item-only-child .ant-menu-title-content{
  padding-left: 40px !important;

} */

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*  The header placeholder. It is displayed when the header is fixed to the top of the
    browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
.ant-input-number,
.ant-picker {
  width: 100% !important;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #042446;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
::-webkit-scrollbar {
  /* width: 8px; */
  /* display: none; */
}
@media all and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .ant-tabs-tab {




  font-family: Roboto-Medium;

} */
/* 
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,

.ant-tabs-tab:hover {

  color: #042446 !important;

}

.ant-tabs-ink-bar {

  background: #042446 !important;

}
.ant-select-selector:hover{
  border-color: #042446 !important;

}
.ant-select-open{
  box-shadow:none;


}
.ant-select-focused{
  border-color: #042446 !important;
}
.ant-select, .ant-select-in-form-item, .ant-select-status-success, .ant-select-single, .ant-select-allow-clear, .ant-select-show-arrow, .ant-select-show-search{
  box-shadow:none
} */
/*
     We are clearing the body's margin and padding, so that the header fits properly.
     We are also adding a height to demonstrate the scrolling behavior. You can remove
     these styles.
 */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title,
.ant-drawer-close {
  color: #000000 !important;
}
.ant-drawer-close {
  display: none !important;
}
.ant-form-item-label > label {
  color: #000000 !important;
  /* color: white !important; */
}

/* .anticon .anticon-close{ */
/* position: absolute !important;
color: #5383d3 !important;
right:2;
top: 2; */
/* } */
/* .ant-drawer-header-title{
  flex: 1 !important;
  color: #75b2f5 !important;
  position:absolute !important;
  align-items: center !important;
} */

/* .ant-btn .ant-btn-primary .ant-btn-dangerous{
  background-color: red !important;
} */

.form-card {
  background: #3c3c3c !important;
  border: none !important;
}

.form-card .ant-card-head {
  border-bottom: 1px solid #646464 !important;
}

.form-card .ant-card-head-title {
  color: white !important;
}

.form-card .ant-empty-description {
  color: white !important;
}

.form-card .ant-empty-img-default-path-5 {
  display: none !important;
}

.form-card .ant-empty-img-default-g {
  display: none !important;
}

.sbEntities-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: #d7d7d74d;
}

#sbEntities > div:last-child {
  opacity: 0;
  transition: opacity 200ms ease;
}

#sbEntities:hover > div:last-child {
  opacity: 1;
}

.access_rights_form label {
  color: black !important;
}

.ant-form-item-label > label:after {
  content: "" !important;
}

.access_rights_form .ant-form-item-label {
  width: 100px;
  text-align: left !important;
}

.access_rights_form .ant-form-item-control {
  flex: 1;
}

.horizontal_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontal_container::-webkit-scrollbar {
  display: none;
}

.ant-form .ant-form-vertical .user_form {
  color: #111;
}
.user_form label {
  color: #042446 !important;
}
.checkout-tabs {
  margin-left: 3rem;
  margin-right: 3rem;
}
.ant-form-horizontal .ant-form-item-label {
  width: 45% !important;
  text-align: left;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #042446 !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(122, 29, 159, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  border-color: #042446;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-drawer-title {
  color: #fff;
}
.ant-drawer-close {
  color: #fff;
}

.date-pos {
  /* position: absolute;
  top: 0; */
  right: 10px;
  font-size: 12px !important;
  color: #000000;
}
.ant-btn .ant-btn-primary {
  background-color: #042446 !important;
}
/* .file-upload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.operator-user {
  flex: 1;
  background-color: "#fff";
  height: "90vh";
  overflow-y: "auto";
}
.test {
  width: 400;
  position: fixed;
  top: 50px;
  background-color: #fef5fb;
  z-index: 2;
  align-items: center;
  margin-top: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
}
.icon-size {
  width: 20px;
}

.sidebar-profile {
  margin-left: 10px !important;
  margin-bottom: 6px !important;
}

.sidebar-name {
  color: #038fdd !important;
  margin-left: 20px !important;
}

.ant-btn-primary {
  background: #0065cd !important;
}

.ant-modal-close-x {
  margin-left: -16px !important;
  /* margin-top: 1.2vh; */
}

.hospital-pagination {
  margin-left: auto;
  margin-top: 20px;
}

.hospital-search {
  min-width: 200px;
  max-width: 200px;
  margin-left: auto;
}
.primary-button {
  color: #fff !important;
  background-color: #036b2f !important;
  border-color: #036b2f !important;
}
.primary-button:hover {
  color: #f1f1f1;
  border-color: #036b2f;
}
.error-button {
  color: #fff !important;
  background-color: #bf0505 !important;
  border-color: #bf0505 !important;
}
.error-button:hover {
  color: #f1f1f1;
  border-color: #bf0505;
}

.ant-table ::-webkit-scrollbar {
  display: block;
  height: 7px;
  width: 7px;
}
.login_form {
  margin-bottom: 3px !important;
}
.ant-input-number-handler-wrap {
  display: none !important;
}

.selected-menu-item {
  color: white !important;
  text-decoration: none !important;
}

/* Custom CSS class for non-selected menu items */
.default-menu-item {
  color: inherit !important;
  text-decoration: none !important;
}

.grid-style-social-media {
  display: "flex";
  flex-direction: column;
  margin: 10px;
}
.stay-connected {
  /* display: flex;
  align-items: flex-start; */
  display: none !important;
}

.reach-us-social-media {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 300px;
  color: white;
}
.reachus-icon {
  cursor: pointer;
  margin: 10px;
  width: 20px;
  height: 20px;
}
.accordion-content {
  font-style: italic;
  color: rgb(173, 167, 167) !important;
}
.custom-button:active {
  transform: scale(0.9);
  transition: transform 0.09s ease-in;
}
.divider {
  display: flex;
  margin: auto !important;
  border-top: 1px solid white;
  width: 88%;
}
.available-list-section {
  display: flex !important;
  flex-direction: column;
  margin-top: 20px;
  color: white;
  font-family: "Lato";
}
/* .custom-square-card {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 200px;
  height: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
} */

.continue_reading {
  text-decoration: underline;
}
.all_stories_text {
  height: 60px;
  /* margin-right: 400px; */
}
.custom-news-story-card {
  min-height: 420px;
  max-height: 550px;
  min-width: 85%;
  max-width: 87%;
  box-shadow: 1px 3px 6px rgba(0, 101, 204, 0.4);
  background-color: #ffff;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}
.signatory_image {
  /* margin-top: 20px !important; */
}
.awards_and_recognition {
  line-height: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  justify-content: space-around;
  margin: 2vw;
}
.joinus-desktop {
  margin: 10px;
}

.simple_article {
}

/* laptop large screen */
/* @media only screen and (min-device-width: 1440px) and (max-width: 2560px) {
  .user_contribution_text {
    width: 40% !important;
    padding: 0 0 0 3% !important;
    background-color: pink !important;
  }
  .fact_check_card_mobile {
    width: 800px !important;
    height: 500px !important;
    border-radius: 20px;
  }
  .fact_check_inside_card_mobile_view {
    width: 500px !important;
    background-color: rgba(20, 15, 45, 0.9);

    margin-top: 307px !important;
  }
  .article_donation_card_small {
    display: none;
  }
  .categories_selector {
    display: none;
  }
  .article_page {
    width: 100%;
    height: 30vh;
    background-color: #140f2d;
    display: flex;
    justify-content: center;
    gap: 25px;
  }
  .article_sub_title {
    font-size: 18px !important;
    color: #140f2d;
  }
  .fact_check_inside_card_width {
    width: 100% !important;
    margin: 4% 0 0 4%;
    align-items: center;
    justify-content: start !important;
    gap: 20px;
    height: 80px;
  }
  .fact_check_inside_card {
    width: 400px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }
} */

/* 

dia only screen and (min-device-width: 1023px) and (max-width: 1440px) { */

.feedback-input {
  height: 50px;
  background-color: transparent !important;
  border-color: #e79432;
  font-size: 15px;
  transition: border-color 0.3s ease;
}

.feedback-input:hover {
  border-color: #e79432;
  outline: none;
}

.feedback-input:focus {
  border-color: #e79432;
  outline: none;
}

.submit-claim-input {
  height: 35px;
  background-color: transparent !important;
  border-color: #ffffff;
  color: white !important;
  font-size: 12px;
  transition: border-color 0.3s ease;
}

.submit-claim-input:hover {
  background-color: transparent !important;
  border-color: #ffffff;
  color: white;
}
.submit-claim-input:focus {
  border-color: #ffffff;
  outline: none;
}

.ant-input-affix-wrapper {
  color: #f0f2f5 !important;
}
/* .ant-input-affix-wrapper > input.ant-input {
  color: #000000 !important;
} */
.desk_menu_select_drop_down {
  border-radius: 10px !important;
  border-color: #0065cc !important;
}
.admin-list-search > .ant-input-wrapper > .ant-input-affix-wrapper {
  background-color: white !important;
  color: #d1d3d5 !important;
}
.admin-list-search
  > .ant-input-wrapper
  > .ant-input-affix-wrapper
  > input.ant-input {
  background-color: white !important;
  color: black !important;
}
.carousel-list {
  width: 100% !important;
  /* padding: 13px; */
}
.carousel-category-list {
  width: 100% !important;
  padding: 13px;
}

.carousel-container {
  width: 100% !important;
  display: "flex";
  justify-content: space-between;
}
feature-story-sub-title-content .add-team-mates {
  border: transparent !important;
}
.delete-team-member {
  border: transparent !important;
}
.team-member-submit-button {
  border: transparent !important;
}

/* selected-key styles */
.category_selected_key {
  width: auto !important;
  background-color: #0065cc;
  color: white !important;
  border: 1px solid #0065cc;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 2vh;
  padding-right: 2vh;
  /* padding: 5px; */
  /* height: 100px; */
  max-height: fit-content;
  transition: background-color 0.2s ease, color 0.2s ease,
    border-color 0.2s ease, transform 0.2s ease;
}
.category_selected_key:hover {
  background-color: #0065cc;
  color: #ffffff;
  border-color: #0065cc;
  transform: scale(1.1);
}
.un_selected_key {
  width: auto;
}
.un_selected_key {
  width: auto;
}
.user-password > input.ant-input {
  background: white !important;
  color: #000000 !important;
}
.user-password {
  background: white !important;
  color: #000000 !important;
  -webkit-text-size-adjust: none !important;
}
.no-zoom-input {
  -webkit-text-size-adjust: 100% !important;
}
/* .no-zoom-input {
  max-height: 1em;  
} */
.user_claim_url:hover {
  color: blue !important;
  text-decoration: none;
  font-weight: bold !important;
}

.member-form-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.column-alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row-alignment {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.center-alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.party-wise-main-content {
  width: 100%;
  height: 200px !important;
  background-color: var(--bread-crumbs-color) !important;
  gap: 25px;
}
.party-wise-content {
  margin-left: 120px;
}
.party-wise-main-description {
  height: 180px;
  justify-content: space-around !important;
}
.party-wise-main-description > text {
  color: white;
  font-size: 2vh;
}
.party-wise-main-tags-list {
  height: auto;
}
.party-wise-main-tags-list {
  margin-top: 40px;
}
.party-wise-browse-content {
  width: 40%;
}
.party-wise-browse-container {
  align-items: center;
}
.see-all-stories-button {
  width: 90%;
  padding: 10px;
  align-items: center;
  align-content: start;
  justify-content: start !important;
}
.see-all-divider {
  width: 30%;
  color: #000000;
  height: 3px;
  background-color: #000000;
}
.trending-stories-container {
  background-color: var(--bread-crumbs-color) !important;
  padding: 30px;
}

.trending-stories-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px; /* Adjust as needed */
  background: linear-gradient(to right, transparent, var(--bread-crumbs-color));
  box-shadow: 10px 0 10px -10px var(--bread-crumbs-color);
}

.carousel-stories-list {
  z-index: 0;
}
.related-stories-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.part-wise-stories-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4.8vh;
}
.fact-check-small-card-container {
  width: 34rem !important;
}

.vertical-fact-check-container {
  min-height: 31rem;
  max-height: 480px;
  box-shadow: rgba(0, 101, 204, 0.1) 10px 10px 10px;
  /* background-color: #821d1d; */
  border: 1px 1px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
}
.article-publish-container {
  width: 100px;
}
.article-reading-container {
  align-items: center;
}
.article-reading-main-container {
  flex-direction: row !important;
  justify-content: space-between !important;
}
.reading-tag {
  color: var(--reading-tag);
  cursor: pointer;
  font-weight: bold;
}
.article-subtitle-text {
  color: gray;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-fact-check-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #0065cc; */
}

.donation-content {
  color: white;
}
.donation-content-middle-container {
  width: 100%;
  display: flex;
}
.story_card {
  height: 100%;

  /* padding-top: 5px; */
  padding: 2% 2% 2% 2%;
  display: flex;
  flex-direction: column;
  /* gap: 2vh; */
  justify-content: space-between;
  /* align-items: center; */
}

.column-alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.row-alignment {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.center-alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.party-wise-main-content {
  width: 100%;
  height: 200px !important;
  background-color: var(--bread-crumbs-color) !important;
  gap: 25px;
}
.party-wise-content {
  margin-left: 120px;
}
.party-wise-main-description {
  height: 180px;
  justify-content: space-around !important;
}
.party-wise-main-description > text {
  color: white;
  font-size: 2vh;
}
.party-wise-main-tags-list {
  height: auto;
}
.party-wise-main-tags-list {
  margin-top: 40px;
}
.party-wise-browse-content {
  width: 40%;
}
.party-wise-browse-container {
  align-items: center;
}
.see-all-stories-button {
  width: 90%;
  padding: 10px;
  align-items: center;
  align-content: start;
  justify-content: start !important;
}
.see-all-divider {
  width: 30%;
  color: #000000;
  height: 3px;
  background-color: #000000;
}
.trending-stories-container {
  background-color: var(--bread-crumbs-color) !important;
  padding: 30px;
}

.trending-stories-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px; /* Adjust as needed */
  background: linear-gradient(to right, transparent, var(--bread-crumbs-color));
  box-shadow: 10px 0 10px -10px var(--bread-crumbs-color);
}

.fact-check-small-card-container {
  width: 34rem !important;
}

.vertical-fact-check-container {
  min-height: 31rem;
  max-height: 480px;
  box-shadow: rgba(0, 101, 204, 0.1) 10px 10px 10px;
  /* background-color: #821d1d; */
  border: 1px 1px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
}
.article-publish-container {
  width: 100px;
}
.article-reading-container {
  align-items: center;
}
.article-reading-main-container {
  flex-direction: row !important;
  justify-content: space-between !important;
}
.reading-tag {
  color: var(--reading-tag);
  cursor: pointer;
  font-weight: bold;
}
.article-subtitle-text {
  color: gray;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-fact-check-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #0065cc; */
}

.trending-stories-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px; /* Adjust as needed */
  background: linear-gradient(to right, transparent, var(--bread-crumbs-color));
  box-shadow: 10px 0 10px -10px var(--bread-crumbs-color);
}

.vertical-fact-check-container {
  min-height: 31rem;
  max-height: 480px;
  box-shadow: rgba(0, 101, 204, 0.1) 10px 10px 10px;
  /* background-color: #821d1d; */
  border: 1px 1px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
}
.article-publish-container {
  width: 100px;
}
.article-reading-container {
  align-items: center;
  gap: 4px;
}
.article-reading-main-container {
  flex-direction: row !important;
  justify-content: space-between !important;
}
.reading-tag {
  color: var(--reading-tag);
  cursor: pointer;
  font-weight: bold;
}
.article-subtitle-text {
  color: gray;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-fact-check-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #0065cc; */
}

.story_card_items {
  height: 7vh;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* margin-top: 5px; */
}
.text-red {
  color: red;
  font-size: 2vh;
}
.minutes {
  font-size: 2vh;
}
.minutes-icon {
  width: 2vh;
}
.article-text-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70% !important;
}
.column-alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row-alignment {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.center-alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.party-wise-main-content {
  width: 100%;
  height: 200px !important;
  background-color: var(--bread-crumbs-color) !important;
  gap: 25px;
}
.party-wise-content {
  margin-left: 120px;
}
.party-wise-main-description {
  height: 180px;
  justify-content: space-around !important;
}
.party-wise-main-description > text {
  color: white;
  font-size: 2vh;
}
.party-wise-main-tags-list {
  height: auto;
}
.party-wise-main-tags-list {
  margin-top: 40px;
}
.party-wise-browse-content {
  width: 40%;
}
.party-wise-browse-container {
  align-items: center;
}
.see-all-stories-button {
  width: 90%;
  padding: 10px;
  align-items: center;
  align-content: start;
  justify-content: start !important;
}
.see-all-divider {
  width: 30%;
  color: #000000;
  height: 3px;
  background-color: #000000;
}

.trending-stories-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px; /* Adjust as needed */
  background: linear-gradient(to right, transparent, var(--bread-crumbs-color));
  box-shadow: 10px 0 10px -10px var(--bread-crumbs-color);
}

.fact-check-small-card-container {
  width: 28rem !important;
  height: 6.5rem;
  display: flex;
  box-shadow: 10px 10px 10px rgba(0, 101, 204, 0.1);
  cursor: pointer;
  border-radius: 10px;
}
.fact-check-mini-content-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vertical-fact-check-container {
  min-height: 31rem;
  max-height: 480px;
  box-shadow: rgba(0, 101, 204, 0.1) 10px 10px 10px;
  /* background-color: #821d1d; */
  border: 1px 1px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
}
.article-publish-container {
  width: 100px;
}
.article-reading-container {
  align-items: center;
  gap: 4px;
}
.article-reading-main-container {
  flex-direction: row !important;
  justify-content: space-between !important;
}
.reading-tag {
  color: var(--reading-tag);
  cursor: pointer;
  font-weight: bold;
}
.article-subtitle-text {
  color: gray;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-fact-check-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #0065cc; */
}

.list-article-main-container {
  background-color: var(--bread-crumbs-color);
  height: 22vw;
  /* padding: 100px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  justify-content: start;
  z-index: 2;
}

.list-article-main-title {
  margin-left: 120px;
}
.list-article-main-image {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list-article-tag > text {
  color: #f49d36;
  font-size: 3vh;
  font-weight: bold;
  font-family: "kufam";
}
.list-article-content-title > text {
  font-family: "lato";
  font-size: 2vh;
  font-weight: bold;
  color: white;
}

.list-article-main-content-container {
  position: relative;
  top: -20vh;
  padding: 50px;
}

.list-article-Continue-read-tag > text {
  font-family: "lato";
  font-size: 1.5vh;
  font-weight: bold;
  color: var(--reading-tag);
  text-decoration: underline;
}
.list-article-image {
  width: 80vh;
  border-radius: 10px;
}

.list-article-image-label {
  top: calc(80vh / -4.5);
  width: calc(80vh / 1.5);
  height: 100px;
  background-color: #0065cc;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list-article-views {
  align-items: center;
}

.column-alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.row-alignment {
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.center-alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.party-wise-main-content {
  width: 100%;
  height: 200px !important;
  background-color: var(--bread-crumbs-color) !important;
  gap: 25px;
}
.party-wise-content {
  margin-left: 120px;
}
.party-wise-main-description {
  height: 180px;
  justify-content: space-around !important;
}
.party-wise-main-description > text {
  color: white;
  font-size: 2vh;
}
.party-wise-main-tags-list {
  height: auto;
}
.party-wise-main-tags-list {
  margin-top: 40px;
}
.party-wise-browse-content {
  width: 40%;
}
.party-wise-browse-container {
  align-items: center;
}
.see-all-stories-button {
  width: 90%;
  padding: 10px;
  align-items: center;
  align-content: start;
  justify-content: start !important;
}
.see-all-divider {
  width: 30%;
  color: #000000;
  height: 3px;
  background-color: #000000;
}

.box-inner-right-shadow {
  position: relative;
}

.trending-stories-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px; /* Adjust as needed */
  background: linear-gradient(to right, transparent, var(--bread-crumbs-color));
  box-shadow: 10px 0 10px -10px var(--bread-crumbs-color);
}

.trending-stories-title > text {
  font-size: 3vh;
  font-weight: bold;
  color: white;
  font-family: Kufam;
  margin-bottom: 40px;
}

.fact-check-small-card-container {
  width: 30rem !important;
}
.part-wise-stories-secondary-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 90vh;
  align-items: end;
  gap: 3vh;
}
.vertical-fact-check-container {
  min-height: 31rem;
  max-height: 480px;
  box-shadow: rgba(0, 101, 204, 0.1) 10px 10px 10px;
  /* background-color: #821d1d; */
  border: 1px 1px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
}
.article-publish-container {
  width: 100px;
}
.article-reading-container {
  align-items: center;
}
.article-reading-main-container {
  flex-direction: row !important;
  justify-content: space-between !important;
}
.reading-tag {
  color: var(--reading-tag);
  cursor: pointer;
  font-weight: bold;
}
.article-subtitle-text {
  color: gray;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-fact-check-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #0065cc; */
}

.donation-main-content_char {
  color: #f49d36;
  font-weight: bold;

  font-size: 10vh;
}

.donation-main-content {
  color: #f49d36;
  font-size: 5vh;
  font-weight: bold;
  line-height: 37px;
  /* margin-top: 25px; */
  font-family: Kufam;
}

.article_top_image {
  /* width: 110vh !important; */
  /* height: 70vh; */
  border-radius: 8px;
  z-index: 1;
}
.react-multi-carousel-item {
  /* width: auto !important; */
}
.react-multi-carousel-track {
  /* gap: 20px !important; */
  /* text-align: center !important; */
}
.react-multiple-carousel__arrow--right {
  /* right: calc(20rem/2) !important; */
}
.donation-and-text-small-card {
  display: flex;
  flex-direction: column;
}

.signup-segment {
  background-color: #ff0000; /* Change to your desired background color */
  color: #ffffff; /* Change to your desired text color */
}

.login-segment {
  background-color: #00ff00; /* Change to your desired background color */
  color: #ffffff; /* Change to your desired text color */
}
.login-segment:hover {
  color: #ffffff !important; /* Change to your desired text color */
}
.quick-access-text {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  margin-top: 10px;
}
.custom-button-disable {
  cursor: not-allowed !important;
  background-color: #0065dfad !important;
}
.button-animation-hide {
  cursor: not-allowed !important;
  background-color: #0065dfad !important;
  transition: none !important;
  transform: none !important;
}
.white-text-label {
  color: white !important;
}

.video_load_button_color {
  background-color: #0065cd;
  color: white !important;
}

.darkmode-icon {
  margin-top: 30px;
}
.donation-input-container {
  display: "flex";
  align-items: "center";
}
.back-btn {
  margin-top: 20px;
  margin-left: 3vw;
}
.search_result_header_content {
  background-color: #140f2d !important;
}
.button_divider {
  height: 2px !important;
  background-color: #0065cc !important;
}

.search_result_trending_now {
  display: flex !important;
  flex-direction: column !important;
}
.recently_added_views-and-reading-time {
  display: flex !important;
}
.search_result_view_and_clock_icon_div {
  display: flex !important;
  flex-direction: row !important;
}
.recently_added_carousel {
  width: 65vw;
  height: 48vh;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0px rgba(0, 101, 204, 0.15) !important;
}
.recently_added_thumbnail_image {
  width: 65vw;
  height: 20vh;
  border-radius: 10px 10px 0 0;
}
.search_result_recently_added_mobile_carousel {
  width: 100% !important;
}
.user_profile_card_division {
  width: 100% !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.user_profile_card {
  background: linear-gradient(
    147.32deg,
    #140f2d 40.85%,
    #f49d36 169.52%
  ) !important;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit_icon_span {
  color: #ffffff;
}
.edit_icon {
}

.user_profile_with_name {
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}
.user_profile_title_card {
  width: 100% !important;
  background-color: #140f2d !important;
}
.user_profile_book_mark_card {
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.profile_card_title_with_icon {
  display: flex !important;
  justify-content: space-between !important;
}
.user_article_count {
  display: flex !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}
.user_article_or_fact_check_count {
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  flex-direction: column;
}
.claim-modal .ant-modal-content {
  padding: 0 !important;
}
.icon_out_line {
  border: 1px solid black;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.count_circle_out_lined {
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.user_details_first_division {
  display: flex !important;
}
.user_profile_details_card {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
}
.about-description {
  color: white !important;
  font-size: 3vh !important;
}

.donation_card_amount {
  display: flex !important;
  justify-content: flex-end !important;
}
.user_claim_donation_list {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  padding: 2% 0 5% 7% !important;
}
.edit_icon_division {
  cursor: pointer !important;
}

.table-cursor {
  cursor: pointer;
}

.leap-frog {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: var(--uib-size);
  padding: 8px 22px;
}

.leap-frog__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 78%;
  height: 20px;
}

.leap-frog__dot::before {
  content: "";
  display: block;
  height: calc(var(--uib-size) * 0.22);
  width: calc(var(--uib-size) * 0.22);
  border-radius: 50%;
  background-color: var(--uib-color);
  will-change: transform;
}

.leap-frog__dot:nth-child(1) {
  animation: leapFrog var(--uib-speed) ease infinite;
}

.leap-frog__dot:nth-child(2) {
  transform: translateX(calc(var(--uib-size) * 0.4));
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -1.5)
    infinite;
}

.leap-frog__dot:nth-child(3) {
  transform: translateX(calc(var(--uib-size) * 0.8)) rotate(0deg);
  animation: leapFrog var(--uib-speed) ease calc(var(--uib-speed) / -3) infinite;
}

@keyframes leapFrog {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  33.333% {
    transform: translateX(0) rotate(180deg);
  }

  66.666% {
    transform: translateX(calc(var(--uib-size) * -0.4)) rotate(180deg);
  }

  99.999% {
    transform: translateX(calc(var(--uib-size) * -0.8)) rotate(180deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}

.cardContainer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}
.user_profile_donation_details {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.user_profile_donation_right_arrow {
  cursor: pointer;
}
.user_profile_titles {
  padding: 3% 0 2% 7%;
}
.admin-user-profile-modal .ant-modal-content {
  padding: 0% !important;
  height: 80vh !important;
}

.custom-modal-title {
  height: 5vh; /* Adjust the height as needed */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.admin-user-profile-text {
  color: gray;
  font-size: 2vh;
  margin-left: 5px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}

.admin-user-profile-details {
  font-size: 1.8vh;
  margin-left: 5px;
  text-align: left;
}

.admin-user-details-icon {
  color: gray;
  cursor: pointer;
}
.user_profile_details_first_div {
  background: linear-gradient(
    147.32deg,
    #140f2d 40.85%,
    #f49d36 169.52%
  ) !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.user_profile_image {
  position: relative !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  flex-direction: column;
}
.user_profile_name_and_role {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.about_me_content {
  padding-top: 2%;
}

.user-profile-personal-details {
  color: black;
  font-size: 2vh;
  margin-left: 5px;
  text-align: left;
  font-weight: bold !important;
}
.user_details_card_text {
  padding: 3% 7% 2% 7%;
}

.user_profile_details_card_division {
  border-radius: 10px;
  background-color: white;
}
.user_profile_divider {
  margin: 8px 0 !important;
  padding: 0% !important;
}

.user_profile_donation_card {
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.user_profile_donation_date {
  display: flex !important;
}
.user_profile_claim_date_and_topic {
  display: flex !important;
  flex-direction: column;
}
.premium_member_text {
  padding-top: 2%;
}
.claims_status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.user_profile_no_data_image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.edit_user_profile_button {
  color: white !important;
  background-color: #140f2d !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 15px !important;
}
.donation_and_contribution_cards {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}
.privileged-access-icon {
  color: white;
  cursor: pointer;
}

.desktop_header_search
  > .ant-input-wrapper
  > .ant-input-affix-wrapper
  > input.ant-input {
  background-color: rgb(0, 255, 72) !important;
  color: #0065cd !important;
}
.custom_new_article_card_inside_div {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
.trending_stories_main_card {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.user_profile_donation_button {
  color: white !important;
  background-color: #140f2d !important;
  font-weight: bold !important;
  border: none !important;
  border-radius: 15px !important;
}
.donation-button-disable {
  cursor: not-allowed !important;
  background-color: #140f2d !important;
}
.followus-card {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.social-media-container-big {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.card-dark {
  background-color: #2d2d2d !important;
  color: white !important;
}

/* .bookmarks-submenu:hover {
  background-color: transparent !important;
} */
.bookmarks-submenu-action {
  background-color: #ffffff !important;
  color: #030303;
}
.bookmarks-submenu-action:hover {
  background-color: #e5e5e5 !important;
  color: black;
}

/* 
.bookmarks-submenu{
  color: red !important;
} */
.contact_us {
  background-color: #140f2d;
  height: auto;
  display: flex;
  flex-direction: column;
}
.contact-page {
  display: flex;
  width: 100%;
}
.contact_us_divider {
  width: 100%;
  display: flex;
  justify-content: center;
}
.contact_us_text_area {
  border-color: #e79432 !important ;
  font-size: 15px !important;
}
.profile_edit_button {
  height: 10vh;
  display: flex;
  justify-content: center;
  padding-top: 3%;
}
.edit_name_and_mob {
  height: 11vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.profile_icon_and_text {
  display: flex;
  height: 5vh;
  align-items: center;
}
.support_us_title {
  width: 100%;
  background-color: #140f2d;
}
.contact_us_icon_and_text {
  display: flex;
  height: 3vh;
  align-items: center;
  border-color: #0065cc !important;
}

.support_us_container {
  padding-bottom: 3%;
}

.video-wrapper > video {
  width: 100% !important;
  height: 300px !important;
}

/* PageNotFound.css */

.page-not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-not-found-content {
  text-align: center;
}

.page-not-found-title {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #333;
}

.page-not-found-message {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.page-not-found-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.page-not-found-button:hover {
  background-color: #0056b3;
}
.editor_profile_card {
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.author_name {
  padding-bottom: 2% !important;
}
.author_profile_count_card {
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.count_title {
  padding-bottom: 7%;
}
.author_name_and_about {
  display: flex;
  flex-direction: column;
}
.single-fact-check-you-turn-logo {
  cursor: pointer !important;
}
.social_media_card {
  border-radius: 10px !important;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn-primary {
  background-color: rgb(0, 172, 238) !important;
}
.my_fact_check_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.bookmark_count_division {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 7%;
}

/* responsive-styles.css */

/* Base styling for the component */
.page-container {
  padding: 20px;
  background-color: #f5f5f5;
}

/* Responsive layout for small screens (e.g., mobile devices) */
@media (max-width: 600px) {
  .sidebar {
    display: none; /* Hide sidebar on small screens */
  }

  .content {
    font-size: 14px; /* Smaller font size for small screens */
    color: red;
  }

  .header {
    text-align: center; /* Center header on small screens */
  }

  .main-image {
    width: 20px;
    border: 2px solid red;
  }
}

/* Responsive layout for larger screens (e.g., tablets and desktops) */
@media (min-width: 601px) {
  .sidebar {
    display: block; /* Show sidebar on larger screens */
    width: 200px; /* Fixed width for the sidebar */
    background-color: #ddd;
  }

  .content {
    font-size: 16px; /* Larger font size for larger screens */
    color: blue;
  }

  .main-image {
    width: 500px;
  }
}

.card_hidden {
  overflow: hidden !important;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.admin_vertical_card_container:hover {
  background-color: rgba(35, 38, 42, 0.8) !important;
}
.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.custom_admin_slot_details_card_div:hover .image {
  opacity: 0.3;
  background-color: rgba(35, 38, 42, 0.8);
}

.custom_admin_slot_details_card_div:hover .middle {
  opacity: 1;
}
.custom_admin_slot_details_card_div:hover .admin_vertical_card_container {
  opacity: 1;
  background-color: rgba(35, 38, 42, 0.8);
}

.text {
  /* background-color: #04aa6d; */
  color: white;
  font-size: 16px;
  /* padding: 16px 32px; */
}
.submit_button {
  background-color: #0065cd;
  color: white;
  border-radius: 6px;
}
.submit_button_div {
  width: 100%;
  display: flex;
  justify-content: end;
}
.slick-track {
  z-index: 000 !important;
}
.last_sync_date_div {
  padding: 0.5em 1em;
  margin-left: 1em;
  background-color: rgb(240, 240, 240);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  font-weight: 500;
  font-size: 1em;
  border: 1px solid rgb(217, 217, 217);
}
.sync_button_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 80%; */
}
.file_name_upload>:where(.css-dev-only-do-not-override-j9bb5n).ant-upload-wrapper {
  display: flex !important;
  gap: 30px !important
}

.iframe-loading {
  position: relative;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@import url("./layout/single_article.css");
@import url("./layout/editor.css");
@import url("screen_767/screen_767_single_article.css");
@import url("screen_768_1023/screen_768_1023_single_article.css");
@import url("./screen_1024_1439/screen_1024_1439_single_article.css");
@import url("./screen_1440_1999/screen_1440_1999_single_article.css");
@import url("./screen_2000_2559/screen_2000_2559_single_article.css");
@import url("./screen_2560_layout/screen_2560_single_article.css");

@import url("./layout/user_single_fact_check.css");
@import url("./screen_767/screen_767_single_fact_check.css");
@import url("./screen_768_1023/screen_768__1023_single_fact_check.css");
@import url("./screen_1024_1439/screen_1024_1439_single_fact_check.css");
@import url("./screen_1440_1999/screen_1440_1999_single_fact_check.css");
@import url("./screen_2000_2559/screen_2000_2559_single_fact_check.css");

 /* Editor.css */
.jodit-workplace {
  max-height: 400px; /* Adjust the height as needed */
  height: 400px !important;
  overflow: auto;
}

/* Custom scrollbar styles */
.jodit-workplace::-webkit-scrollbar {
  width: 20px;
}

.jodit-workplace::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.jodit-workplace::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.jodit-workplace::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* Iframe custom scrollbar styles */
iframe::-webkit-scrollbar {
  width: 20px;
}

iframe::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

iframe::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

iframe::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.featured-story-button{
margin-left: 10px;
margin-top: 8px;
background-color: #0065cd !important;
color: white;
}

.admin_fact_check_form .ant-modal .css-dev-only-do-not-override-j9bb5n {
width: auto;
}

.rectangle-tag {
  border-radius: 0; /* Removes rounded corners */
  padding: 5px 15px; /* Adjust the padding to make it more rectangular */
  background-color: #f0f0f0; /* Customize background color */
  border: 1px solid #d9d9d9; /* Optional: Customize border */
}
.custom-dropdown-menu {
  background-color: green !important;
  color: white !important;
}

@supports (-ms-ime-align: auto) {
  input[type="password"]::-ms-reveal {
    display: none !important;
  }
}
